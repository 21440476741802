<div *ngIf="!isNotFound" class="header-container">
  <container-element class="general-information" [ngSwitch]="shareviewContext">
    <ng-container *ngIf="validShareviewKey">
      <shareview-move-general-information
        [generalInformation]="generalInformationDto"
        *ngSwitchCase="'move'"
      ></shareview-move-general-information>
    </ng-container>
    <ng-container *ngIf="validShareviewKey">
      <shareview-sync-general-information
        [generalInformation]="generalInformationDto"
        *ngSwitchCase="'sync'"
      ></shareview-sync-general-information>
    </ng-container>
  </container-element>
  <div class="logo-container">
    <p class="product-name">Dualog Shareview</p>
    <dp-icon [size]="40" type="digitalplatform"> </dp-icon>
  </div>
</div>
