<dp-page-container>
  <div *ngIf="!isSearchingGlobal" class="row">
    <div class="col-12 col-xl-7 mb-lg-4">
      <shareview-transmission-list
        (packageSelectedEvent)="packageSelected($event)"
      ></shareview-transmission-list>
    </div>
    <div
      class="col-12 col-lg-4 offset-xl-1 offset-lg-0"
      id="sourceDest"
      [class.sticky]="sticky"
      [class.notSticky]="!sticky"
    >
      <shareview-source
        *ngIf="!loading"
        [packages]="selectedPackageSources"
      ></shareview-source>
      <shareview-destinations
        *ngIf="!loading"
        [packages]="selectedDestinationsPackages"
      ></shareview-destinations>
    </div>
  </div>
</dp-page-container>
