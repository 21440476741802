<dp-header4 class="dp-mb-4">Task Source</dp-header4>

<dp-expandable-list
  [loading]="isLoading && sources.length === 0"
  [items]="sources"
  [isHeaderVisible]="false"
  (expanded)="toggleSource()"
>
  <dp-column field="destinationStatus" width="60px">
    <dp-cell *dpCellDef="let item">
      <dp-header-cell *dpHeaderCellDef></dp-header-cell>
      <dp-icon
        *ngIf="item.sourceStatus === SourceStatus.Uploading"
        type="la-sync-alt"
        class="icon"
        [style.background]="'var(--dualog-blue8)'"
      ></dp-icon>
      <dp-icon
        *ngIf="item.sourceStatus === SourceStatus.Uploaded"
        type="la-check"
        class="icon"
        [style.background]="'var(--dualog-aqua)'"
        color="var(--dualog-white)"
      ></dp-icon>
      <dp-icon
        *ngIf="item.sourceStatus === SourceStatus.Registered"
        type="la-satellite-dish"
        class="icon"
        [style.background]="'var(--dualog-blue8)'"
      ></dp-icon>
    </dp-cell>
  </dp-column>
  <dp-column field="leafNodeName">
    <dp-header-cell *dpHeaderCellDef>Name</dp-header-cell>
    <dp-cell *dpCellDef="let item" [title]="item.leafNodeName">
      <div class="d-flex flex-column">
        {{ item?.leafNodeName }}
        <dp-progress-bar
          *ngIf="item.sourceStatus === SourceStatus.Uploading"
          [progress]="calculateProgress(item)"
          [isFullWidth]="true"
        ></dp-progress-bar>
        <dp-detail-type>
          <ng-container *ngIf="item.sourceStatus === SourceStatus.Uploaded">
            Up-To-Date
          </ng-container>
          <ng-container *ngIf="item.sourceStatus === SourceStatus.Registered">
            Waiting
          </ng-container>
        </dp-detail-type>
      </div>
    </dp-cell>
  </dp-column>
  <dp-column field="endUpload">
    <dp-header-cell *dpHeaderCellDef>Current version</dp-header-cell>
    <dp-cell *dpCellDef="let item">
      <div class="d-flex flex-column">
        {{ item.endUpload | dpDate }}
      </div>
    </dp-cell>
  </dp-column>
  <ng-template dpListExpandTemplate let-item>
    <td width="60px"></td>
    <td>
      <dp-body-type class="dp-mt-8" small block>Progress</dp-body-type>
      <dp-body-type small block>Duration</dp-body-type>
      <dp-body-type class="dp-mb-6" small block>Status</dp-body-type>
    </td>
    <td colspan="2">
      <dp-support-type class="dp-mt-8" block>
        {{ item.bytesConfirmed | bytes : 2 }} /
        <ng-container
          *ngIf="item.versionSize || !item.bytesConfirmed; else unknown"
        >
          {{ item.versionSize | bytes : 2 }}
        </ng-container>
        <ng-template #unknown> Unknown </ng-template>
      </dp-support-type>
      <dp-support-type block>
        <span
          *ngIf="
            item.sourceStatus === SourceStatus.Uploaded;
            else elseNotUploadedYet
          "
        >
          {{ getDuration(item.startUpload, item.endUpload) }}
        </span>
        <ng-template #elseNotUploadedYet> &nbsp; </ng-template>
      </dp-support-type>
      <dp-support-type class="dp-mb-6" block>
        {{ item.sourceStatus }}
      </dp-support-type>
    </td>
  </ng-template>
</dp-expandable-list>
