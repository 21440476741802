/**
 * ShareView - public API
 * Public facing API for Dualog Drive.
 *
 * The version of the OpenAPI document: v1
 * Contact: support@dualog.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrgUnitType } from './orgUnitType';
import { ExternalClientType } from './externalClientType';
import { PackageTransmissionStatus } from './packageTransmissionStatus';


export interface TypedDestinationStatusDto { 
    leafNodeId?: string;
    externalClientType?: ExternalClientType;
    leafNodeName?: string | null;
    clientId?: string;
    clientName?: string | null;
    status?: PackageTransmissionStatus;
    orgUnitType?: OrgUnitType;
}
export namespace TypedDestinationStatusDto {
}


