import { Injectable } from '@angular/core';
import { SyncSourceDto } from '@shareview/libs/generated/shareview-api';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SyncService {
  private source$ = new BehaviorSubject<SyncSourceDto>({});
  selectedSource$ = this.source$.asObservable();

  setSource(source: SyncSourceDto): void {
    this.source$.next(source);
  }
}
