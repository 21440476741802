import {
  DestinationStatusDto,
  SyncSourceDto,
} from '@shareview/libs/generated/shareview-api';
import { SyncDestinationDto } from '@shareview/shareview/data';

export function sortByDestinationStatus<
  T extends { status?: DestinationStatusDto }
>(a: T, b: T) {
  const map = {
    [DestinationStatusDto.NotStarted]: 2,
    [DestinationStatusDto.Registered]: 3,
    [DestinationStatusDto.Downloading]: 1,
    [DestinationStatusDto.Downloaded]: 4,
  };
  if (map[a.status] < map[b.status]) {
    return -1;
  } else if (map[a.status] > map[b.status]) {
    return 1;
  } else {
    return 0;
  }
}

export function sortByDestinationShipName<
  T extends {
    status?: DestinationStatusDto;
    destinationLeafNodeName?: string | null;
  }
>(a: T, b: T) {
  if (a.status === b.status) {
    if (a.destinationLeafNodeName < b.destinationLeafNodeName) {
      return -1;
    } else if (a.destinationLeafNodeName > b.destinationLeafNodeName) {
      return 1;
    }
  }
  return 0;
}

export function getDetinations(sources: SyncSourceDto[]): SyncDestinationDto[] {
  return sources
    .flatMap((source) =>
      source.destinations?.map((destination) => ({
        ...destination,
        configComputedId: source.configComputedId,
      }))
    )
    .sort(sortByDestinationStatus)
    .sort(sortByDestinationShipName);
}
