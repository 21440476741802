import { Injectable } from '@angular/core';
import { GeneralInformationWithSourcesDto } from '@shareview/libs/generated/shareview-api';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class GeneralInformationService {
  private generalInformation$ =
    new BehaviorSubject<GeneralInformationWithSourcesDto>({});
  selectedGeneralInformation$ = this.generalInformation$.asObservable();

  setGeneralInformationWithSourcesDto(
    generalInformationWithSourcesDto: GeneralInformationWithSourcesDto
  ): void {
    this.generalInformation$.next(generalInformationWithSourcesDto);
  }
}
