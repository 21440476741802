/**
 * ShareView - public API
 * Public facing API for Dualog Drive.
 *
 * The version of the OpenAPI document: v1
 * Contact: support@dualog.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type OverallTaskStatus = 'Disabled' | 'PendingSource' | 'PendingDestinations' | 'AllDestinationsUpToDate';

export const OverallTaskStatus = {
    Disabled: 'Disabled' as OverallTaskStatus,
    PendingSource: 'PendingSource' as OverallTaskStatus,
    PendingDestinations: 'PendingDestinations' as OverallTaskStatus,
    AllDestinationsUpToDate: 'AllDestinationsUpToDate' as OverallTaskStatus
};

