/**
 * ShareView - public API
 * Public facing API for Dualog Drive.
 *
 * The version of the OpenAPI document: v1
 * Contact: support@dualog.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type OrgUnitType = 'Office' | 'OfficeGroup' | 'Ship' | 'ShipGroup' | 'SharePoint' | 'AmazonS3' | 'Unknown';

export const OrgUnitType = {
    Office: 'Office' as OrgUnitType,
    OfficeGroup: 'OfficeGroup' as OrgUnitType,
    Ship: 'Ship' as OrgUnitType,
    ShipGroup: 'ShipGroup' as OrgUnitType,
    SharePoint: 'SharePoint' as OrgUnitType,
    AmazonS3: 'AmazonS3' as OrgUnitType,
    Unknown: 'Unknown' as OrgUnitType
};

