<dp-header2>{{ taskName }}</dp-header2>
<div class="sub-header">
  <div class="no-icon">
    <dp-header5>Sync task |</dp-header5>
    <dp-support-type> Copy link</dp-support-type>
    <dp-button
      type="icon"
      icon="clipboard"
      [noPadding]="true"
      [fullWidth]="false"
      (click)="copyField()"
    ></dp-button>
  </div>

  <div class="details col-12">
    <div class="detail">
      <dp-detail-type>Folder type</dp-detail-type>
      <dp-lead-type [truncate]="true">
        {{ folderType }}
      </dp-lead-type>
    </div>
    <div class="detail">
      <dp-detail-type>
        {{ templateType === TemplateType.None ? 'Source' : 'Ship' }}
      </dp-detail-type>

      <dp-lead-type
        *ngIf="templateType === TemplateType.None"
        [truncate]="true"
      >
        {{ currentSource?.sourceLeafNodeName }}
      </dp-lead-type>

      <dp-filter-dropdown
        *ngIf="sources.length && templateType === TemplateType.ManyToOne"
        [items]="sources"
        uniqueField="sourceLeafNodeId"
        displayField="sourceLeafNodeName"
        placeholder="Search for ship"
        [value]="currentSource"
        (selected)="sourceChanged($event)"
      ></dp-filter-dropdown>
      <dp-filter-dropdown
        *ngIf="destinations?.length && templateType === TemplateType.OneToMany"
        [items]="destinations"
        uniqueField="destinationLeafNodeId"
        displayField="destinationLeafNodeName"
        placeholder="Search for ship"
        [value]="currentDestination"
        (selected)="destinationChanged($event)"
      ></dp-filter-dropdown>
    </div>
    <div class="detail">
      <dp-detail-type>Latest Version</dp-detail-type>
      <dp-lead-type [truncate]="true">
        #{{ lastVersionNo }} - {{ lastVersionTime! | dpDate }}
      </dp-lead-type>
    </div>
    <div class="detail">
      <dp-detail-type>Total filesize</dp-detail-type>
      <dp-lead-type [truncate]="true">
        {{ totalFileSize | bytes : 0 }}
      </dp-lead-type>
    </div>
  </div>
</div>
