import { Component, Input, OnInit } from '@angular/core';
import { GeneralInformationDto } from '@shareview/libs/generated/shareview-api';
import { copyField } from '@shareview/shared/utilis';
import { ShareViewMoveService } from '@shareview/shareview/data';
import { getShareviewKey } from '@shareview/shared/utilis';

@Component({
  selector: 'shareview-move-general-information',
  templateUrl: './general-information.component.html',
  styleUrls: ['./general-information.component.scss'],
})
export class GeneralInformationComponent implements OnInit {
  @Input() generalInformation: GeneralInformationDto;
  shareviewKey!: string;
  taskName!: string;
  shareViewUrl!: string;

  constructor(private shareViewMoveService: ShareViewMoveService) {}

  ngOnInit() {
    this.shareviewKey = getShareviewKey();
    this.shareViewMoveService
      .incrementMoveCounter(this.shareviewKey)
      .pipe()
      .subscribe();

    this.taskName = this.generalInformation.taskName!;
    this.shareViewUrl = this.generalInformation.url!;
  }

  copyField() {
    if (this.shareViewUrl) copyField(this.shareViewUrl);
  }
}
