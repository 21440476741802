import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  GeneralInformationWithSourcesDto,
  ResponseSyncDestinationStatusDto,
  ResponseSyncVersionSourceStatusDto,
  SyncSourceDto,
  TemplateType,
  V1SyncVersionContentDto,
  V1SyncVersionDto,
} from '@shareview/libs/generated/shareview-api';
import { getShareviewKey } from '@shareview/shared/utilis';
import { ShareViewSyncService } from '@shareview/shareview/data';
import { Subscription, first, skip } from 'rxjs';
import { SyncService } from '../shared/sync.service';
import { Router } from '@angular/router';
import { DArray } from '@dualog/design-system';
import { getDetinations } from '../general-information/utils/destination-utils';
import { GeneralInformationService } from '../shared/general-information.service';

@Component({
  selector: 'shareview-landingpage',
  templateUrl: './landingpage.component.html',
  styleUrls: [],
})
export class LandingpageComponent implements OnInit, OnDestroy {
  shareviewKey!: string;
  configComputedId: string;
  versions: V1SyncVersionDto[] = [];
  latestVersion: V1SyncVersionDto;
  selectedVersion: V1SyncVersionDto;
  source: ResponseSyncVersionSourceStatusDto = null;
  isLoadingSyncContent = false;
  pollSubscription: Subscription;
  syncVersionContent: V1SyncVersionContentDto;
  isFirstInitialisation = true;
  destination: ResponseSyncDestinationStatusDto;
  isLoadingSource = false;
  isLoadingDestinations = false;
  selectedSource: SyncSourceDto;

  constructor(
    private shareViewSyncService: ShareViewSyncService,
    private syncService: SyncService,
    private generalInformationService: GeneralInformationService
  ) {}

  ngOnInit(): void {
    this.shareviewKey = getShareviewKey();

    this.shareViewSyncService
      .incrementSyncCounter(this.shareviewKey)
      .pipe(first())
      .subscribe();

    this.syncService.selectedSource$.subscribe((source: SyncSourceDto) => {
      this.selectedSource = source;
      this.configComputedId = source.configComputedId;
      this.getSyncContent();
    });

    this.generalInformationService.selectedGeneralInformation$
      .pipe(skip(1))
      .subscribe((data: GeneralInformationWithSourcesDto) => {
        if (data?.templateType === TemplateType.OneToMany) {
          const destinations = DArray.sortByProperty(
            getDetinations(data?.sources),
            'destinationLeafNodeName'
          );

          this.configComputedId = this.getConfigComputedId(
            destinations[0]?.configComputedId
          );
        } else {
          this.configComputedId = this.getConfigComputedId(
            data?.sources[0]?.configComputedId
          );
        }

        this.getSyncContent();
      });
  }

  getSyncVersionContent(
    shareviewKey: string,
    configComputedId: string,
    versionNo: number
  ): void {
    this.isLoadingSyncContent = true;
    this.shareViewSyncService
      .getContentForVersion(shareviewKey, configComputedId, versionNo)
      .subscribe((content: V1SyncVersionContentDto) => {
        if (content) {
          this.isLoadingSyncContent = false;
          this.syncVersionContent = content;
        }
      });
  }

  onVersionChange(version: V1SyncVersionDto): void {
    this.selectedVersion = version;
    this.configComputedId = version.configComputedId;
    this.getSyncContent();
  }

  getSourceStatus(): void {
    this.isLoadingSource = true;
    this.shareViewSyncService
      .getSourceStatus(
        this.shareviewKey,
        this.configComputedId,
        this.latestVersion.versionNo
      )
      .subscribe({
        next: (sourceStatus: ResponseSyncVersionSourceStatusDto) => {
          this.source = sourceStatus;
          this.isLoadingSource = false;
        },
        error: (error) => {
          console.log('Failed to get source status.');
          this.isLoadingSource = false;
        },
      });
  }

  getDestinationStatus(): void {
    this.isLoadingDestinations = true;
    this.shareViewSyncService
      .getDestinationStatus(this.shareviewKey, this.configComputedId)
      .subscribe({
        next: (destinationStatus: ResponseSyncDestinationStatusDto) => {
          this.destination = destinationStatus;
          this.isLoadingDestinations = false;
        },
        error: (error) => {
          console.log('Failed to get destination status.');
          this.isLoadingDestinations = false;
        },
      });
  }

  ngOnDestroy(): void {
    this.pollSubscription.unsubscribe();
  }

  private getSyncContent() {
    if (this.configComputedId !== undefined) {
      this.shareViewSyncService
        .getVersions(this.shareviewKey, this.configComputedId)
        .subscribe({
          next: (versions: V1SyncVersionDto[]) => {
            this.versions = versions;
            this.latestVersion = this.getLatestVersion();
            this.selectedVersion = this.isFirstInitialisation
              ? this.latestVersion
              : this.selectedVersion;
            this.isFirstInitialisation = false;
            this.getSyncVersionContent(
              this.shareviewKey,
              this.configComputedId,
              this.selectedVersion.versionNo
            );
            this.getSourceStatus();
            this.getDestinationStatus();
          },
          error: (error) => {
            console.log('Failed to get versions.');
          },
        });
    }
  }

  private getConfigComputedId(defaultComputedConfigId: string) {
    return this.selectedSource?.configComputedId === undefined
      ? defaultComputedConfigId
      : this.selectedSource?.configComputedId;
  }

  private getLatestVersion() {
    return this.versions.reduce(
      (acc, cum) => (acc?.versionNo > cum?.versionNo ? acc : cum),
      null
    );
  }
}
