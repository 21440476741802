/**
 * ShareView - public API
 * Public facing API for Dualog Drive.
 *
 * The version of the OpenAPI document: v1
 * Contact: support@dualog.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ItemActionDto = 'Created' | 'Modified' | 'Deleted' | 'None';

export const ItemActionDto = {
    Created: 'Created' as ItemActionDto,
    Modified: 'Modified' as ItemActionDto,
    Deleted: 'Deleted' as ItemActionDto,
    None: 'None' as ItemActionDto
};

