import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  Configuration,
  GeneralInformationDto,
  MoveService,
} from '@shareview/libs/generated/shareview-api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ShareViewMoveService {
  api: MoveService;

  constructor(
    public http: HttpClient,
    @Inject('environment') environment: { [key: string]: string }
  ) {
    const url = environment['shareviewApi'];
    this.api = new MoveService(http, url, new Configuration({ basePath: url }));
  }

  public getGeneralInformation(
    shareViewKey: string
  ): Observable<GeneralInformationDto> {
    return this.api.getMoveGeneralInformation(shareViewKey);
  }

  public getCompletedMovePackagesForShareViewKey(
    shareViewKey: string
  ): Observable<any> {
    return this.api.getCompletedMovePackagesForShareViewKey(
      shareViewKey,
      0,
      100
    );
  }

  public getOngoingMovePackagesForShareViewKey(
    shareViewKey: string
  ): Observable<any> {
    return this.api.getOngoingMovePackagesForShareViewKey(shareViewKey, 0, 100);
  }

  public getSourceStatus(
    shareViewKey: string,
    configComputedId: string,
    packageId: string
  ): Observable<any> {
    return this.api.getSourceStatus(shareViewKey, configComputedId, packageId);
  }

  public getDestinationStatus(
    shareViewKey: string,
    configComputedId: string,
    packageId: string
  ): Observable<any> {
    return this.api.getDestinationStatus(
      shareViewKey,
      configComputedId,
      packageId
    );
  }

  public incrementMoveCounter(shareViewKey: string): Observable<boolean> {
    return this.api.incrementMoveCounter(shareViewKey);
  }
}
