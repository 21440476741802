import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { DpPaginatorEvent } from '@dualog/design-system';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
  setDisplayedStatus,
  setIconType,
  setFileTitle,
} from './package-card-utils';
import { SelectedCompletedPackageInfo } from './models/selected-completed-package-info.model';
import {
  OverallPackageStatus,
  ResponseFileDto,
  SourceStatusDto,
} from '@shareview/libs/generated/shareview-api';

@UntilDestroy()
@Component({
  selector: 'shareview-package-card',
  templateUrl: './package-card.component.html',
  styleUrls: ['./package-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PackageCardComponent {
  @Input() package: any;
  @Input() index = 0;
  @Input() organisationInfo: any;
  @Input() isExpanded = false;
  @Input() isSelected = false;
  @Input() extendedCardContentIndex: any = 0;
  @Input() isTransferringTabSelected = true;

  @Output()
  selectedTransferringPackage: EventEmitter<SelectedCompletedPackageInfo> =
    new EventEmitter<SelectedCompletedPackageInfo>();
  @Output()
  selectedCompletedPackage: EventEmitter<SelectedCompletedPackageInfo> =
    new EventEmitter<SelectedCompletedPackageInfo>();
  @Output()
  changedExtendedCardContentIndex: EventEmitter<number> =
    new EventEmitter<number>();

  SourceStatus = SourceStatusDto;
  isSearching = false;
  searchText = '';
  selectedPackagePaginatorIndex: number | undefined = 0;
  pageOptions: DpPaginatorEvent = {
    pageSize: 10,
  };
  setFileTitle = setFileTitle;

  onSelectTransferringPackage(
    selectedPackageIndex: number,
    pck: SelectedCompletedPackageInfo
  ): void {
    this.selectedTransferringPackage.emit(
      this.mapToSelectedCompletedPackageInfo(selectedPackageIndex, pck)
    );
  }

  onSelectCompletePackage(
    selectedPackageIndex: number,
    pck: SelectedCompletedPackageInfo
  ): void {
    this.selectedCompletedPackage.emit(
      this.mapToSelectedCompletedPackageInfo(selectedPackageIndex, pck)
    );
  }

  onExpandContent(showContent: boolean, cardIndex: number): void {
    this.selectedPackagePaginatorIndex = 0;
    this.extendedCardContentIndex = showContent ? cardIndex : null;
    this.changedExtendedCardContentIndex.emit(this.extendedCardContentIndex);
  }

  onFilesIndexChanged(options: DpPaginatorEvent): void {
    this.selectedPackagePaginatorIndex = options.pageIndex;
  }

  onItemsSearched(event: { searchText: string }): void {
    this.searchText = event.searchText;
  }

  onFileSearch(searchText: string): void {
    this.isSearching = searchText.length > 0;
  }

  filterSearchedFiles(files: ResponseFileDto[]): ResponseFileDto[] {
    return files.filter((file) =>
      this.doesSearchTextMatchFilename(file.fileName!, this.searchText)
    );
  }

  setDisplayedStatus(status: OverallPackageStatus): string {
    return setDisplayedStatus(status);
  }

  setIconType(type: string): string {
    return setIconType(type);
  }

  private doesSearchTextMatchFilename(
    fileName: string,
    searchText: string
  ): boolean {
    return fileName
      .toLocaleLowerCase()
      .includes(searchText.toLocaleLowerCase());
  }

  private mapToSelectedCompletedPackageInfo(
    selectedPackageIndex: number,
    pck: SelectedCompletedPackageInfo
  ): SelectedCompletedPackageInfo {
    return {
      selectedPackageIndex,
      pck,
    } as SelectedCompletedPackageInfo;
  }
}
