import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { saveShareviewKey } from '@shareview/shared/utilis';
import {
  ShareViewMoveService,
  ShareViewSyncService,
} from '@shareview/shareview/data';
import {
  GeneralInformationDto,
  GeneralInformationWithSourcesDto,
} from '@shareview/libs/generated/shareview-api';

@Component({
  selector: 'shareview-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  public shareviewContext: string | undefined;
  public shareviewKey: string | undefined;
  public isNotFound!: boolean;
  public generalInformationDto:
    | GeneralInformationDto
    | GeneralInformationWithSourcesDto;
  validShareviewKey!: boolean;

  constructor(
    private Location: Location,
    private shareViewMoveService: ShareViewMoveService,
    private shareViewSyncService: ShareViewSyncService
  ) {}

  ngOnInit() {
    this.shareviewContext = this.Location.path().split('/')[1];
    this.shareviewKey = this.Location.path().split('/')[2];
    saveShareviewKey(this.shareviewKey);
    if (this.shareviewContext === 'move') {
      this.shareViewMoveService
        .getGeneralInformation(this.shareviewKey)
        .subscribe(
          (data: GeneralInformationDto) => {
            this.generalInformationDto = data;
            this.validShareviewKey = true;
          },
          (error) => {
            if (error.status === 404) {
              this.validShareviewKey = false;
              this.isNotFound = true;
            }
          }
        );
    } else if (this.shareviewContext === 'sync') {
      this.shareViewSyncService
        .getGeneralInformation(this.shareviewKey)
        .subscribe(
          (data: GeneralInformationWithSourcesDto) => {
            this.generalInformationDto = data;
            this.validShareviewKey = true;
          },
          (error) => {
            if (error.status === 404) {
              this.validShareviewKey = false;
              this.isNotFound = true;
            }
          }
        );
    } else {
      this.isNotFound = true;
    }
  }
}
