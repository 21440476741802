import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { getDualogDate, getDualogTime } from '@dualog/design-system';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ResponseSyncVersionDto } from '@shareview/libs/generated/shareview-api';
import { getShareviewKey } from '@shareview/shared/utilis';
import { ShareViewSyncService } from '@shareview/shareview/data';
import { BytesPipe } from 'ngx-pipes';
import { groupBy, sortBy } from 'lodash';
import { SyncTaskVersionViewModel } from './models/sync-task-versions-view.model';

@UntilDestroy()
@Component({
  selector: 'shareview-sync-task-versions-history-modal',
  templateUrl: './sync-task-versions-history.modal.html',
  styleUrls: ['./sync-task-versions-history.modal.scss'],
})
export class SyncTaskVersionsHistoryModal implements OnInit {
  isModalOpen = false;
  isLoading = false;
  syncFileVersionGroups: any = null;
  hasMoreRows = true;
  fileNameSearch = '';
  shareviewKey!: string;

  private computedConfigId: string;
  private startRow: number;
  private currentFileNameSearch: string;
  private syncFileVersions: SyncTaskVersionViewModel[] = [];

  constructor(
    private cdr: ChangeDetectorRef,
    private bytesPipe: BytesPipe,
    private shareViewSyncService: ShareViewSyncService
  ) {}

  ngOnInit() {
    this.shareviewKey = getShareviewKey();
  }

  async openModal(computedConfigId: string) {
    this.computedConfigId = computedConfigId;
    this.startRow = 0;
    this.fileNameSearch = '';
    this.currentFileNameSearch = '';
    this.syncFileVersions = [];
    this.isModalOpen = true;
    await this.fetchFileVersionHistory();
  }

  closeModal(): void {
    this.isModalOpen = false;
  }

  async showOlderHistory() {
    await this.fetchFileVersionHistory();
  }

  getFileVersionHistoryString(version: any) {
    const tokens: any[] = [];
    if (version.modTime !== '1 Jan 1 00:00') {
      tokens.push(version.modTime + ' UTC');
    }
    if (!version.isFolder && version.size > 0) {
      tokens.push(this.bytesPipe.transform(version.size, 2));
    }
    if (version.folderName) {
      tokens.push(version.action + ' in ' + version.folderName);
    }
    if (tokens.length) {
      return tokens.join(' - ');
    }
    return '';
  }

  filterFileVersionHistory() {
    if (this.currentFileNameSearch !== this.fileNameSearch) {
      this.startRow = 0;
      this.currentFileNameSearch = this.fileNameSearch;
      this.syncFileVersions = [];
      this.fetchFileVersionHistory();
    }
  }

  getGroupOfFilesSize(versionGroup: any) {
    let total = 0;
    versionGroup.forEach((file: any) => {
      total += file.size;
    });
    return total;
  }

  private async fetchFileVersionHistory() {
    try {
      this.isLoading = true;
      this.shareViewSyncService
        .getFileHistory(
          this.shareviewKey,
          this.computedConfigId,
          this.startRow,
          this.currentFileNameSearch
        )
        .subscribe((response: any) => {
          this.syncFileVersions = [
            ...this.syncFileVersions,
            ...this.mapToViewModel(response?.syncVersions),
          ];
          const versionGroups = groupBy(
            this.syncFileVersions,
            (version: any) => version.serverTime
          );
          this.syncFileVersionGroups = sortBy(
            versionGroups,
            (group: any) => group.key
          );
          this.startRow += 10;
          this.hasMoreRows = response.totalNumberOfRows > this.startRow;
        });
    } catch (err) {
      console.log('Failed to get file history.');
    } finally {
      this.isLoading = false;
      this.cdr.detectChanges();
    }
  }

  private mapToViewModel(dtoSyncTaskVersions: ResponseSyncVersionDto[]): any[] {
    return dtoSyncTaskVersions.flatMap((version) =>
      version.fileEvents.map((event) => {
        const serverTime: Date = new Date(version.serverTime);
        const modTime: Date = new Date(event.modTime);
        const vm: any = {
          serverTime: `${getDualogDate(serverTime)} ${getDualogTime(
            serverTime
          )}`,
          versionNo: version.versionNo,
          itemName: event.itemName,
          modTime: `${getDualogDate(modTime)} ${getDualogTime(modTime)}`,
          folderName: event.folderName,
          isFolder: event.isFolder,
          action: event.action,
          size: event.size,
        };
        return vm;
      })
    );
  }
}
