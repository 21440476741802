import { Component, HostListener } from '@angular/core';
import {
  ResponseMoveDestinationStatusDto,
  ResponseMoveSourceStatusDto,
} from '@shareview/libs/generated/shareview-api';
import { ShareViewMoveService } from '@shareview/shareview/data';

@Component({
  selector: 'shareview-landingpage',
  templateUrl: './landingpage.component.html',
  styleUrls: ['./landingpage.component.scss'],
})
export class LandingpageComponent {
  isSearchingGlobal!: boolean;
  loading = false;
  selectedPackageSources: ResponseMoveSourceStatusDto[] = [];
  selectedDestinationsPackages: ResponseMoveDestinationStatusDto[] = [];
  sticky = false;

  constructor(private shareViewMoveService: ShareViewMoveService) {}

  packageSelected($event: any) {
    if ($event.shareviewKey == '') {
      this.selectedPackageSources = [];
      this.selectedDestinationsPackages = [];
    } else {
      this.shareViewMoveService
        .getSourceStatus(
          $event.shareviewKey,
          $event.computedConfigId,
          $event.packageId
        )
        .subscribe((data: ResponseMoveSourceStatusDto) => {
          this.selectedPackageSources = [];
          this.selectedPackageSources.push(data);
        });
      this.shareViewMoveService
        .getDestinationStatus(
          $event.shareviewKey,
          $event.computedConfigId,
          $event.packageId
        )
        .subscribe((data: ResponseMoveDestinationStatusDto[]) => {
          this.selectedDestinationsPackages = data;
        });
    }
  }

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    const windowScroll = window.pageYOffset;
    const initialLeft = document.getElementById('sourceDest')?.offsetLeft;
    if (windowScroll >= 230 && window.innerWidth > 1200) {
      this.sticky = true;
      document.getElementById('sourceDest')!.style.left =
        initialLeft?.toString() + 'px';
      document.getElementById('sourceDest')!.style.margin = '0';
    } else {
      this.sticky = false;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (window.innerWidth > 1200) {
      document.getElementById('sourceDest')!.style.left = '57%';
    } else {
      document.getElementById('sourceDest')!.style.left = '1%';
    }
  }
}
