import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  GeneralInformationWithSourcesDto,
  SyncSourceDto,
  TemplateType,
} from '@shareview/libs/generated/shareview-api';
import { copyField, getShareviewKey } from '@shareview/shared/utilis';
import {
  ShareViewSyncService,
  SyncDestinationDto,
} from '@shareview/shareview/data';
import { SyncService } from '../shared/sync.service';
import {
  EMPTY,
  Subscription,
  catchError,
  delay,
  interval,
  switchMap,
  take,
} from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { DArray } from '@dualog/design-system';
import { getDetinations } from './utils/destination-utils';
import { GeneralInformationService } from '../shared/general-information.service';

@Component({
  selector: 'shareview-sync-general-information',
  templateUrl: './general-information.component.html',
  styleUrls: ['./general-information.component.scss'],
})
export class GeneralInformationComponent implements OnInit, OnDestroy {
  @Input() generalInformation: GeneralInformationWithSourcesDto;
  TemplateType = TemplateType;

  currentSource?: SyncSourceDto | null;
  currentDestination?: SyncDestinationDto | null;
  shareviewKey!: string;
  taskName!: string;
  shareViewUrl!: string;
  folderType!: string;
  sources!: Array<SyncSourceDto>;
  destinations?: Array<SyncDestinationDto>;
  totalFileSize: number = 0;
  lastVersionNo: number = 0;
  lastVersionTime: Date = new Date();
  templateType!: TemplateType;
  emptyTask: boolean = false;
  pollSubscription: Subscription;

  private readonly _pollFrequency = 20000;

  constructor(
    private shareViewSyncService: ShareViewSyncService,
    private syncService: SyncService,
    private generalInformationService: GeneralInformationService
  ) {}

  ngOnInit(): void {
    this.setHeaderContent(this.generalInformation);
    this.generalInformationService.setGeneralInformationWithSourcesDto(
      this.generalInformation
    );
    this.shareviewKey = getShareviewKey();

    this.pollSubscription = interval(this._pollFrequency)
      .pipe(
        delay(this._pollFrequency),
        switchMap(() =>
          this.shareViewSyncService
            .getGeneralInformation(this.shareviewKey)
            .pipe(
              take(1),
              catchError((error: HttpErrorResponse) => {
                console.log('Failed to get general information.');
                return EMPTY;
              })
            )
        )
      )
      .subscribe((data: GeneralInformationWithSourcesDto) => {
        this.generalInformationService.setGeneralInformationWithSourcesDto(
          data
        );
        this.setHeaderContent(data);
      });
  }

  copyField(): void {
    if (this.shareViewUrl) copyField(this.shareViewUrl);
  }

  sourceChanged(source: SyncSourceDto): void {
    this.currentSource = source;
    this.syncService.setSource(source);
    this.lastVersionNo = source.lastVersionNo!;
    this.lastVersionTime = source.lastVersionTime!;
    this.getGeneralInformation();
  }

  destinationChanged(destination: SyncDestinationDto): void {
    this.currentDestination = destination;

    this.sourceChanged(
      this.sources[
        this.findIndex(this.sources, {
          configComputedId: destination.configComputedId,
        })
      ]
    );
  }

  findIndex(sources: SyncSourceDto[], currentSource: SyncSourceDto): number {
    return sources.findIndex(
      (source: SyncSourceDto) =>
        source.configComputedId === currentSource.configComputedId
    );
  }

  getGeneralInformation(): void {
    this.shareViewSyncService
      .getGeneralInformation(this.shareviewKey)
      .subscribe({
        next: (data: GeneralInformationWithSourcesDto) =>
          this.setHeaderContent(data),
        error: (error) => console.log('Failed to get general information.'),
      });
  }

  setHeaderContent(data: GeneralInformationWithSourcesDto): void {
    this.taskName = data.taskName!;
    this.shareViewUrl = data.url!;
    this.folderType = data.folderType!;
    this.sources = data.sources!;
    this.currentSource =
      this.currentSource === undefined ? this.sources[0] : this.currentSource;
    const currentSourceIndex = this.findIndex(this.sources, this.currentSource);
    this.totalFileSize = this.sources[currentSourceIndex]?.totalFileSize;
    this.lastVersionNo = this.sources[currentSourceIndex]?.lastVersionNo;
    this.lastVersionTime = this.sources[currentSourceIndex]?.lastVersionTime;
    this.templateType = data.templateType;

    if (this.templateType === TemplateType.OneToMany) {
      this.destinations = DArray.sortByProperty(
        getDetinations(this.sources),
        'destinationLeafNodeName'
      );

      this.currentDestination =
        this.currentDestination === undefined
          ? this.destinations[0]
          : this.currentDestination;
    }
  }

  ngOnDestroy(): void {
    this.pollSubscription.unsubscribe();
  }
}
