/**
 * ShareView - public API
 * Public facing API for Dualog Drive.
 *
 * The version of the OpenAPI document: v1
 * Contact: support@dualog.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface V1SyncVersionDto { 
    id?: string;
    configComputedId?: string;
    configId?: string;
    versionNo?: number;
    serverTime?: Date;
}

