import { Route } from '@angular/router';
import { NotFoundComponent } from './not-found/not-found.component';

export const appRoutes: Route[] = [
  {
    path: 'move/:shareviewKey',
    loadChildren: () =>
      import('@shareview/shareview/feature/move').then(
        (m) => m.ShareviewFeatureMoveModule
      ),
  },
  {
    path: 'sync/:shareviewKey',
    loadChildren: () =>
      import('@shareview/shareview/feature/sync').then(
        (m) => m.ShareviewFeatureSyncModule
      ),
  },
  {
    path: '**',
    pathMatch: 'full',
    component: NotFoundComponent,
  },
];
