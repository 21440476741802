import { DestinationStatusDto } from '@shareview/libs/generated/shareview-api';

export function sortTasksByDestinationStatus<
  T extends { destinationStatus?: DestinationStatusDto }
>(a: T, b: T) {
  const map = {
    [DestinationStatusDto.Registered]: 2,
    [DestinationStatusDto.NotStarted]: 2,
    [DestinationStatusDto.Downloading]: 1,
    [DestinationStatusDto.Downloaded]: 3,
  };
  if (map[a.destinationStatus] < map[b.destinationStatus]) {
    return -1;
  } else if (map[a.destinationStatus] > map[b.destinationStatus]) {
    return 1;
  } else {
    return 0;
  }
}

export function sortTasksByShipNameOnSortedByStatus<
  T extends {
    destinationStatus?: DestinationStatusDto;
    leafNodeName?: string | null;
  }
>(a: T, b: T) {
  if (a.destinationStatus === b.destinationStatus) {
    if (a.leafNodeName < b.leafNodeName) {
      return -1;
    } else if (a.leafNodeName > b.leafNodeName) {
      return 1;
    }
  }
  return 0;
}
