/**
 * ShareView - public API
 * Public facing API for Dualog Drive.
 *
 * The version of the OpenAPI document: v1
 * Contact: support@dualog.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DestinationStatusDto } from './destinationStatusDto';
import { ExternalClientType } from './externalClientType';


export interface ResponseSingleDestinationStatusDto { 
    leafNodeId?: string;
    leafNodeName?: string | null;
    externalClientType?: ExternalClientType;
    destinationId?: string;
    destinationName?: string | null;
    currentVersion?: number;
    currentVersionTime?: Date;
    ongoingFromVersion?: number;
    ongoingToVersion?: number;
    ongoingSize?: number;
    ongoingEta?: number;
    ongoingBytesConfirmed?: number;
    ongoingDestinationStatus?: DestinationStatusDto;
    destinationStatus?: DestinationStatusDto;
}
export namespace ResponseSingleDestinationStatusDto {
}


