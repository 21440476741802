/**
 * ShareView - public API
 * Public facing API for Dualog Drive.
 *
 * The version of the OpenAPI document: v1
 * Contact: support@dualog.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ItemActionDto } from './itemActionDto';


export interface ResponseSyncFileEventDto { 
    transactionNo?: number;
    itemName?: string | null;
    modTime?: Date;
    size?: number;
    folderName?: string | null;
    isFolder?: boolean;
    action?: ItemActionDto;
}
export namespace ResponseSyncFileEventDto {
}


