/**
 * ShareView - public API
 * Public facing API for Dualog Drive.
 *
 * The version of the OpenAPI document: v1
 * Contact: support@dualog.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SourceStatusDto } from './sourceStatusDto';
import { ResponseSyncDestinationDto } from './responseSyncDestinationDto';
import { OverallTaskStatus } from './overallTaskStatus';
import { ExternalClientType } from './externalClientType';


export interface SyncSourceDto { 
    configComputedId?: string;
    externalClientTypeSource?: ExternalClientType;
    sourceId?: string;
    sourceClientName?: string | null;
    sourceLeafNodeId?: string;
    sourceStatus?: SourceStatusDto;
    status?: OverallTaskStatus;
    sourceLeafNodeName?: string | null;
    lastVersionNo?: number;
    lastVersionTime?: Date | null;
    destinations?: Array<ResponseSyncDestinationDto> | null;
    numberOfFiles?: number;
    totalFileSize?: number;
}
export namespace SyncSourceDto {
}


