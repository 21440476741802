/**
 * ShareView - public API
 * Public facing API for Dualog Drive.
 *
 * The version of the OpenAPI document: v1
 * Contact: support@dualog.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ItemActionDto } from './itemActionDto';


export interface V1SyncFileDto { 
    name?: string | null;
    versionNo?: number;
    action?: ItemActionDto;
    lastModification?: Date;
    size?: number;
}
export namespace V1SyncFileDto {
}


