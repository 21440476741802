/**
 * ShareView - public API
 * Public facing API for Dualog Drive.
 *
 * The version of the OpenAPI document: v1
 * Contact: support@dualog.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type TemplateType = 'None' | 'OneToMany' | 'ManyToOne';

export const TemplateType = {
    None: 'None' as TemplateType,
    OneToMany: 'OneToMany' as TemplateType,
    ManyToOne: 'ManyToOne' as TemplateType
};

