<div class="file-version-history align-items-center">
  <dp-dropdownlist
    [options]="versions"
    [value]="selectedVersion"
    optionField="folderPreviewLabel"
    placeholder="Version"
    [showClear]="false"
    (changed)="onVersionChange($event)"
    class="version-dropdown dp-mr-4"
  >
    <ng-template dpDropdownTemplate let-option>
      #{{ option.versionNo }} –
      {{ option.serverTime | dpDateTime }}
    </ng-template>
  </dp-dropdownlist>

  <dp-button
    type="tertiary"
    [disabled]="!selectedVersion"
    (clicked)="onViewVersionHistory()"
    class="file-version-history-btn"
  >
    File Version History
  </dp-button>
</div>

<div class="dp-my-8">
  <dp-checkbox
    [value]="showLatestChangedFiles"
    [preventDefault]="false"
    label="Only show changed files"
    tabIndex="0"
    (changed)="toggleChangedFiles()"
  ></dp-checkbox>
</div>

<div class="folder-preview scroller">
  <dp-icon-loading *ngIf="isLoading"></dp-icon-loading>

  <ng-template
    *ngTemplateOutlet="
      files;
      context: { files: _syncVersionContent?.files, root: true }
    "
  ></ng-template>

  <cdk-tree [dataSource]="dataSource" [treeControl]="treeControl">
    <cdk-nested-tree-node
      *cdkTreeNodeDef="let node"
      [class.expanded]="isExpanded(node)"
      [class.deleted]="node.action === ItemAction.Deleted"
    >
      <button [attr.aria-label]="'Toggle ' + node.name" cdkTreeNodeToggle>
        <dp-icon
          [type]="isExpanded(node) ? 'la-angle-up' : 'la-angle-down'"
          [color]="
            isExpanded(node) ? 'var(--dualog-aqua)' : 'var(--dualog-blue)'
          "
        ></dp-icon>
        <span
          class="filename"
          [ngClass]="{
            filename:
              isAnyFileModified(node) !== undefined &&
              (node.action !== ItemAction.Deleted ||
                node.action !== ItemAction.Created) &&
              node.versionNo !== _syncVersionContent?.versionNo
          }"
          [class.deleted]="node.action === ItemAction.Deleted"
        >
          {{ node.name }}
        </span>
        <dp-chip
          *ngIf="node.versionNo === _syncVersionContent?.versionNo"
          [text]="fileActionMap[node.action]"
          [type]="node.action === ItemAction.Deleted ? 'warning' : 'standard'"
          class="dp-ml-4"
        ></dp-chip>
      </button>

      <ng-container *ngIf="isExpanded(node)">
        <ng-template
          *ngTemplateOutlet="files; context: { files: node.files }"
        ></ng-template>
      </ng-container>
    </cdk-nested-tree-node>

    <cdk-nested-tree-node
      *cdkTreeNodeDef="let node; when: hasChildren"
      [class.expanded]="isExpanded(node)"
      [class.deleted]="node.action === ItemAction.Deleted"
    >
      <button [attr.aria-label]="'Toggle ' + node.name" cdkTreeNodeToggle>
        <dp-icon
          [type]="isExpanded(node) ? 'la-angle-up' : 'la-angle-down'"
          [color]="
            isExpanded(node) ? 'var(--dualog-aqua)' : 'var(--dualog-blue)'
          "
        ></dp-icon>
        <span
          class="filename"
          [ngClass]="{ filename: isAnyFileModified(node) !== undefined }"
        >
          {{ node.name }}
        </span>
      </button>

      <ng-container *ngIf="isExpanded(node)">
        <ng-template
          *ngTemplateOutlet="files; context: { files: node.files }"
        ></ng-template>
      </ng-container>

      <ul [class.d-none]="!isExpanded(node)">
        <ng-container cdkTreeNodeOutlet></ng-container>
      </ul>
    </cdk-nested-tree-node>
  </cdk-tree>
</div>

<ng-template #files let-files="files" let-root="root">
  <ul *ngIf="files?.length > 0" [class.no-padding-left]="root">
    <li
      *ngFor="let file of files"
      [class.deleted]="file.action === ItemAction.Deleted"
    >
      <dp-icon type="la-file" class="dp-mr-2"></dp-icon>
      {{ file.name }} {{ _syncVersionContent?.versionNo }}
      <dp-chip
        *ngIf="file.versionNo === _syncVersionContent?.versionNo"
        [text]="fileActionMap[file.action]"
        [type]="file.action === ItemAction.Deleted ? 'warning' : 'standard'"
        class="dp-ml-4"
      ></dp-chip>
    </li>
  </ul>
</ng-template>

<shareview-sync-task-versions-history-modal></shareview-sync-task-versions-history-modal>
