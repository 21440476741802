/**
 * ShareView - public API
 * Public facing API for Dualog Drive.
 *
 * The version of the OpenAPI document: v1
 * Contact: support@dualog.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SourceStatusDto } from './sourceStatusDto';
import { ExternalClientType } from './externalClientType';


export interface ResponseMoveSourceStatusDto { 
    packageId?: string;
    serverTime?: Date;
    size?: number;
    sourceStatus?: SourceStatusDto;
    externalClientType?: ExternalClientType;
    sourceId?: string;
    sourceName?: string | null;
    leafNodeId?: string;
    leafNodeName?: string | null;
    uploadStarted?: Date | null;
    uploadEnded?: Date | null;
    bytesUploaded?: number;
}
export namespace ResponseMoveSourceStatusDto {
}


