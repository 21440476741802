export const enumToMap = (
  inputEnum: any,
  inputMap: { [key: string]: any }
): { [key: string]: any } => {
  const keys = Object.values(inputEnum) as string[];
  const values = keys.filter((key, index) => keys.indexOf(key) === index);
  const map: { [key: string]: any } = {};
  values.forEach((value) => {
    map[value] = inputMap && inputMap[value] ? inputMap[value] : value;
  });
  return map;
};
