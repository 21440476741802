/**
 * ShareView - public API
 * Public facing API for Dualog Drive.
 *
 * The version of the OpenAPI document: v1
 * Contact: support@dualog.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DestinationStatusDto } from './destinationStatusDto';
import { ExternalClientType } from './externalClientType';


export interface ResponseSyncDestinationDto { 
    externalClientTypeDestination?: ExternalClientType;
    destinationId?: string;
    destinationClientName?: string | null;
    destinationLeafNodeId?: string;
    destinationLeafNodeName?: string | null;
    status?: DestinationStatusDto;
}
export namespace ResponseSyncDestinationDto {
}


