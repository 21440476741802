/**
 * ShareView - public API
 * Public facing API for Dualog Drive.
 *
 * The version of the OpenAPI document: v1
 * Contact: support@dualog.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SourceStatus } from './sourceStatus';
import { ExternalClientType } from './externalClientType';


export interface ResponseSyncVersionSourceStatusDto { 
    configComputedId?: string;
    configId?: string;
    externalClientType?: ExternalClientType;
    sourceId?: string;
    leafNodeId?: string;
    leafNodeName?: string | null;
    versionNo?: number;
    versionSize?: number;
    sourceStatus?: SourceStatus;
    bytesConfirmed?: number;
    startUpload?: Date | null;
    endUpload?: Date | null;
}
export namespace ResponseSyncVersionSourceStatusDto {
}


