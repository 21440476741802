import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  Configuration,
  GeneralInformationWithSourcesDto,
  ResponseSyncDestinationStatusDto,
  ResponseSyncFilesHistoryHeaderDto,
  ResponseSyncVersionSourceStatusDto,
  SyncService,
  V1SyncVersionContentDto,
  V1SyncVersionDto,
} from '@shareview/libs/generated/shareview-api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ShareViewSyncService {
  api: SyncService;

  constructor(
    public http: HttpClient,
    @Inject('environment') environment: { [key: string]: string }
  ) {
    const url = environment['shareviewApi'];
    this.api = new SyncService(http, url, new Configuration({ basePath: url }));
  }

  public getGeneralInformation(
    shareviewKey: string
  ): Observable<GeneralInformationWithSourcesDto> {
    return this.api.getSyncGeneralInformation(shareviewKey);
  }

  public getVersions(
    shareviewKey: string,
    configComputedId: string
  ): Observable<V1SyncVersionDto[]> {
    return this.api.versions(shareviewKey, configComputedId);
  }

  public getSourceStatus(
    shareviewKey: string,
    configComputedId: string,
    versionNo: number
  ): Observable<ResponseSyncVersionSourceStatusDto> {
    return this.api.getSyncTaskSourceStatus(
      shareviewKey,
      configComputedId,
      versionNo
    );
  }

  public getDestinationStatus(
    shareviewKey: string,
    configComputedId: string
  ): Observable<ResponseSyncDestinationStatusDto> {
    return this.api.getSyncTaskDestinationStatus(
      shareviewKey,
      configComputedId
    );
  }

  public getContentForVersion(
    shareviewKey: string,
    configComputedId: string,
    versionNo: number
  ): Observable<V1SyncVersionContentDto> {
    return this.api.getSyncTaskContentForGivenVersion(
      shareviewKey,
      configComputedId,
      versionNo
    );
  }

  public getFileHistory(
    shareviewKey: string,
    configComputedId: string,
    startRow: number,
    fileNameSearch?: string
  ): Observable<ResponseSyncFilesHistoryHeaderDto> {
    return this.api.getFileHistoryInTask(
      shareviewKey,
      configComputedId,
      startRow,
      fileNameSearch
    );
  }

  public incrementSyncCounter(shareviewKey: string): Observable<boolean> {
    return this.api.incrementSyncCounter(shareviewKey);
  }
}
