import { DestinationStatusDto } from '@shareview/libs/generated/shareview-api';

export function sortMoveDestinationsByDestinationStatus<
  T extends { status?: DestinationStatusDto }
>(a: T, b: T) {
  const map = {
    [DestinationStatusDto.Registered]: 2,
    [DestinationStatusDto.NotStarted]: 4,
    [DestinationStatusDto.Downloading]: 1,
    [DestinationStatusDto.Downloaded]: 5,
    [DestinationStatusDto.Failed]: 3,
  };
  if (map[a.status] < map[b.status]) {
    return -1;
  } else if (map[a.status] > map[b.status]) {
    return 1;
  } else {
    return 0;
  }
}

export function sortMoveDestinationsByDestinationShipName<
  T extends {
    status?: DestinationStatusDto;
    leafNodeName?: string | null;
  }
>(a: T, b: T) {
  if (a.status === b.status) {
    if (a.leafNodeName < b.leafNodeName) {
      return -1;
    } else if (a.leafNodeName > b.leafNodeName) {
      return 1;
    }
  }
  return 0;
}
