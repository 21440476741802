<dp-header4 class="dp-mb-4">Task Source</dp-header4>

<dp-expandable-list
  [items]="packages"
  [isHeaderVisible]="false"
  [loading]="isLoading && packages.length === 0"
  [expandedRowIds]="expandedRowsIds"
  (expanded)="onSourceExpanded($event)"
>
  <dp-column field="destinationStatus" width="60px">
    <dp-cell *dpCellDef="let item">
      <dp-header-cell *dpHeaderCellDef></dp-header-cell>
      <dp-icon
        *ngIf="item.sourceStatus === SourceStatus.Uploading"
        type="la-sync-alt"
        class="icon"
        [style.background]="'var(--dualog-blue8)'"
      ></dp-icon>
      <dp-icon
        *ngIf="item.sourceStatus === SourceStatus.Uploaded"
        type="la-check"
        class="icon"
        [style.background]="'var(--dualog-aqua)'"
        color="var(--dualog-white)"
      ></dp-icon>
      <dp-icon
        *ngIf="item.sourceStatus === SourceStatus.Registered"
        type="la-satellite-dish"
        class="icon"
        [style.background]="'var(--dualog-blue8)'"
      ></dp-icon>
      <dp-icon
        *ngIf="item.sourceStatus === SourceStatus.Failed"
        type="la-satellite-dish"
        class="icon"
        [style.background]="'var(--dualog-blue8)'"
      ></dp-icon>
    </dp-cell>
  </dp-column>
  <dp-column field="leafNodeName">
    <dp-header-cell *dpHeaderCellDef>Name</dp-header-cell>
    <dp-cell *dpCellDef="let item" [title]="item.leafNodeName">
      <div class="d-flex flex-column">
        {{ item?.leafNodeName }}
        <dp-progress-bar
          [progress]="calculateProgress(item)"
          [isFullWidth]="true"
        ></dp-progress-bar>
      </div>
    </dp-cell>
  </dp-column>
  <dp-column field="endUpload">
    <dp-header-cell *dpHeaderCellDef>Current version</dp-header-cell>
    <dp-cell *dpCellDef="let item">
      <div class="d-flex flex-column text-align-right">
        <dp-support-type class="no-margin">
          <ng-container
            *ngIf="
              item.sourceStatus === SourceStatus.Registered;
              else elseIfIsUploading
            "
          >
            Waiting
          </ng-container>
          <ng-template #elseIfIsUploading>
            <ng-container
              *ngIf="
                item.sourceStatus === SourceStatus.Uploading;
                else elseIfIsUploaded
              "
            >
              Uploading
            </ng-container>
          </ng-template>
          <ng-template #elseIfIsUploaded>
            <ng-container
              *ngIf="
                item.sourceStatus === SourceStatus.Uploaded;
                else elseIfFailed
              "
            >
              Uploaded
            </ng-container>
          </ng-template>
          <ng-template #elseIfFailed>
            <ng-container *ngIf="item.sourceStatus === SourceStatus.Failed">
              Failed
            </ng-container>
          </ng-template>
        </dp-support-type>
      </div>
    </dp-cell>
  </dp-column>
  <ng-template dpListExpandTemplate let-item>
    <td width="60px"></td>
    <td>
      <dp-body-type class="dp-mt-8" small block>Duration</dp-body-type>
      <dp-body-type class="dp-mb-8" small block>Started</dp-body-type>
    </td>
    <td colspan="2">
      <dp-support-type class="dp-mt-8" block>
        <span
          *ngIf="
            item.sourceStatus === SourceStatus.Uploaded;
            else elseNotUploadedYet
          "
        >
          {{ getDuration(item.uploadStarted, item.uploadEnded) }}
        </span>
        <ng-template #elseNotUploadedYet> &nbsp; </ng-template>
      </dp-support-type>
      <dp-support-type class="dp-mb-8" block>
        {{ item.uploadStarted | dpDateTime }}
      </dp-support-type>
    </td>
  </ng-template>
</dp-expandable-list>
