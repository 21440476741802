/**
 * ShareView - public API
 * Public facing API for Dualog Drive.
 *
 * The version of the OpenAPI document: v1
 * Contact: support@dualog.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ResponseSyncVersionDto } from './responseSyncVersionDto';


export interface ResponseSyncFilesHistoryHeaderDto { 
    computedConfigid?: string;
    startRow?: number;
    numberOfRows?: number;
    totalNumberOfRows?: number;
    syncVersions?: Array<ResponseSyncVersionDto> | null;
}

