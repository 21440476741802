import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { LandingpageComponent } from './landingpage/landingpage.component';

const routes: Route[] = [
    {
        path: 'sync/:shareviewKey', component: LandingpageComponent
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
  })
  export class SyncRoutingModule {}