import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { DateTimePipe } from '@dualog/design-system';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
  ItemActionDto,
  V1SyncFileDto,
  V1SyncFolderDto,
  V1SyncVersionContentDto,
  V1SyncVersionDto,
} from '@shareview/libs/generated/shareview-api';
import { NestedTreeControl } from '@angular/cdk/tree';
import { SyncTaskVersionsHistoryModal } from './sync-task-versions-history-modal/sync-task-versions-history.modal';
import { ArrayDataSource } from '@angular/cdk/collections';
import { enumToMap } from './sync-folder-preview-utils';

@UntilDestroy()
@Component({
  selector: 'shareview-sync-folder-preview',
  templateUrl: './sync-folder-preview.component.html',
  styleUrls: ['./sync-folder-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SyncFolderPreviewComponent implements OnChanges {
  @ViewChild(SyncTaskVersionsHistoryModal)
  modalSyncTaskVersionsHistory: SyncTaskVersionsHistoryModal;

  @Input() selectedVersion: V1SyncVersionDto;
  @Input() versions: V1SyncVersionDto[] = [];
  @Input() latestVersion: V1SyncVersionDto;
  @Input() isLoading: boolean;

  ItemAction = ItemActionDto;
  treeControl = new NestedTreeControl<V1SyncFolderDto>((node) => node.folders);
  dataSource: ArrayDataSource<V1SyncFolderDto>;
  showLatestChangedFiles = false;
  topLevelFiles: V1SyncFileDto[];
  configComputedId: string;
  fileActionMap = enumToMap(ItemActionDto, {
    [ItemActionDto.Created]: 'Added',
  });
  _syncVersionContent: V1SyncVersionContentDto;

  @Input() set syncVersionContent(content: V1SyncVersionContentDto) {
    if (content) {
      this._syncVersionContent = content;
      this.topLevelFiles = this._syncVersionContent.files;
      const expandedNodes = this.treeControl.expansionModel.selected;
      this.treeControl.collapseAll();
      expandedNodes.forEach((expandedNode: V1SyncFolderDto) => {
        this.restoreExpandedNodes(content, expandedNode);
      });
      this.dataSource = new ArrayDataSource(content.folders);
      this.showFolderPreviewContent();
    }
  }

  @Output() versionChanged = new EventEmitter<V1SyncVersionDto>();

  ngOnChanges(changes: SimpleChanges): void {
    this.versions = this.versions.map((version: V1SyncVersionDto) => ({
      ...version,
      folderPreviewLabel: this.createFolderPreviewVersionLabel(version),
    }));
  }

  restoreExpandedNodes(node: V1SyncFolderDto, expandedNode: V1SyncFolderDto) {
    node.folders?.forEach((folder: V1SyncFolderDto) => {
      if (
        folder.name === expandedNode.name &&
        folder.action === expandedNode.action
      ) {
        this.treeControl.expand(folder);
      }
      if (folder.folders.length > 0) {
        this.restoreExpandedNodes(folder, expandedNode);
      }
    });
  }

  onVersionChange(version: V1SyncVersionDto): void {
    this.selectedVersion = version;
    this.treeControl.collapseAll();
    this.versionChanged.emit(version);
  }

  createFolderPreviewVersionLabel(version: V1SyncVersionDto): string {
    const serverTime = new DateTimePipe().transform(version.serverTime);
    return `#${version.versionNo} - ${serverTime}`;
  }

  onViewVersionHistory() {
    this.modalSyncTaskVersionsHistory.openModal(
      this.selectedVersion?.configComputedId
    );
  }

  isAnyFileModified(node: V1SyncFolderDto): V1SyncFileDto | undefined {
    let foundChild;
    if (node?.files.length > 0) {
      foundChild = node?.files.find(
        (file: V1SyncFileDto) =>
          file.versionNo === this._syncVersionContent?.versionNo
      );
      if (foundChild === undefined) {
        foundChild = node?.folders.find((folder: V1SyncFolderDto) =>
          this.isAnyFileModified(folder)
        );
      }
    } else if (foundChild === undefined && node?.folders.length > 0) {
      foundChild = node?.folders.find((folder: V1SyncFolderDto) =>
        this.isAnyFileModified(folder)
      );
    } else {
      if (node.versionNo === this._syncVersionContent?.versionNo) {
        foundChild = node;
      } else {
        foundChild = undefined;
      }
    }
    return foundChild;
  }

  toggleChangedFiles(): void {
    this.showLatestChangedFiles = !this.showLatestChangedFiles;
    this.showFolderPreviewContent();
  }

  showFolderPreviewContent(): void {
    if (this.showLatestChangedFiles) {
      const changedFolders = this._syncVersionContent?.folders.filter(
        (folder) => this.isAnyFileModified(folder)
      );
      changedFolders.forEach((item) => this.filterLatestChangedFiles(item));
      let changedTopLevelFiles: V1SyncFileDto[] = [];
      if (this._syncVersionContent?.files.length > 0) {
        changedTopLevelFiles = this._syncVersionContent.files.filter(
          (file: V1SyncFileDto) => this.isLatestVersion(file.versionNo)
        );
      }
      this._syncVersionContent.files = changedTopLevelFiles;
      this.dataSource = new ArrayDataSource(changedFolders);
    } else {
      this._syncVersionContent.files = this.topLevelFiles;
      this.dataSource = new ArrayDataSource(this._syncVersionContent.folders);
    }
  }

  filterLatestChangedFiles(item: V1SyncFolderDto): void {
    if (item.files.length > 0) {
      item.files = item.files.filter((file: V1SyncFileDto) =>
        this.isLatestVersion(file.versionNo)
      );
    }
    if (item.folders.length > 0) {
      item.folders.forEach((folder: V1SyncFolderDto) => {
        this.filterLatestChangedFiles(folder);
      });
    }
  }

  hasChildren = (_: number, node: V1SyncFolderDto) =>
    !!node.folders && node.folders.length > 0;

  isExpanded = (folder: V1SyncFolderDto) => this.treeControl.isExpanded(folder);

  isLatestVersion(versionNo: number): boolean {
    return versionNo === this._syncVersionContent?.versionNo;
  }
}
