import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
  DestinationStatusDto,
  ResponseSingleDestinationStatusDto,
  ResponseSyncDestinationStatusDto,
} from '@shareview/libs/generated/shareview-api';
import { calculateProgress } from '@shareview/shared/utilis';
import {
  sortTasksByDestinationStatus,
  sortTasksByShipNameOnSortedByStatus,
} from './sync-destinations-utils';

@UntilDestroy()
@Component({
  selector: 'shareview-sync-destinations',
  templateUrl: './sync-destinations.component.html',
  styleUrls: ['./sync-destinations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SyncDestinationsComponent {
  DestinationStatus = DestinationStatusDto;
  destinations: ResponseSingleDestinationStatusDto[] = [];
  filteredDestinations: ResponseSingleDestinationStatusDto[] = [];

  private _destinationsIds: Set<string> = new Set<string>();

  @Input()
  set destination(value: ResponseSyncDestinationStatusDto) {
    if (value) {
      this.destinations = value.destinations
        .sort(sortTasksByDestinationStatus)
        .sort(sortTasksByShipNameOnSortedByStatus);
      this.destinations
        .filter((destination) =>
          this._destinationsIds.has(destination.destinationId)
        )
        .map((destination) => ((destination as any).isExpanded = true));
    }
  }

  @Input() isLoading: boolean;

  onItemsFiltered(event: {
    items: ResponseSingleDestinationStatusDto[];
  }): void {
    this.filteredDestinations = event.items;
  }

  onDestinationExpanded(destination: ResponseSingleDestinationStatusDto): void {
    if ((destination as any).isExpanded) {
      this._destinationsIds.add(destination.destinationId);
    } else {
      this._destinationsIds.delete(destination.destinationId);
    }
  }

  calculateProgress(task: any) {
    return calculateProgress(task.ongoingBytesConfirmed, task.ongoingSize);
  }
}
