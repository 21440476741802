import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { ShareViewMoveService } from '@shareview/shareview/data';
import { DpPaginatorEvent } from '@dualog/design-system';
import {
  OverallPackageStatus,
  ResponseFileDto,
  ResponsePackageWithContentDto,
} from '@shareview/libs/generated/shareview-api';
import { getShareviewKey } from '@shareview/shared/utilis';
import { SelectedCompletedPackageInfo } from './package-card/models/selected-completed-package-info.model';

@Component({
  selector: 'shareview-transmission-list',
  templateUrl: './transmission-list.component.html',
  styleUrls: ['./transmission-list.component.scss'],
})
export class TransmissionListComponent implements OnInit {
  @Output() packageSelectedEvent = new EventEmitter<any>();
  _pollFrequency = 20000;
  loading: boolean = false;
  transferringPackages: ResponsePackageWithContentDto[] = [];
  filteredTransferringPackages!: ResponsePackageWithContentDto[];
  completedPackages!: ResponsePackageWithContentDto[];
  filteredCompletedPackages!: ResponsePackageWithContentDto[];
  isSearching!: boolean;
  currentTabSelected = 0;
  selectedPackageIndex = 0;
  DEFAULT_PACKAGE_NUMBER = 0;
  searchResult!: ResponseFileDto[];
  searchedFiles!: string;
  extendedCardContentIndex: number | null = null;
  globalSearchedFiles!: string;
  selectedPackagePaginatorIndex = 0;
  selectedPackage: any;

  shareviewKey!: string;

  pageOptions: DpPaginatorEvent = {
    pageSize: 10,
    pageIndex: 0,
  };

  constructor(
    private shareViewMoveService: ShareViewMoveService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.shareviewKey = getShareviewKey();

    this.getOngoingMovePackages();
    this.getCompletedMovePackages();

    setInterval(() => {
      this.getOngoingMovePackages();
      this.getCompletedMovePackages();
    }, this._pollFrequency);
  }

  get _filteredTransferringPackages(): ResponsePackageWithContentDto[] {
    return this.filteredTransferringPackages?.slice(
      this.pageStart,
      this.pageStop
    );
  }

  set _filteredTransferringPackages(transferringPackages: any[]) {
    this.filteredTransferringPackages = transferringPackages;
  }

  get _filteredCompletedPackages(): ResponsePackageWithContentDto[] {
    return this.filteredCompletedPackages?.slice(this.pageStart, this.pageStop);
  }

  set _filteredCompletedPackages(completedPackages: any[]) {
    this.filteredCompletedPackages = completedPackages;
  }

  get pageStart() {
    return this.pageOptions?.pageIndex * this.pageOptions.pageSize;
  }

  get pageStop() {
    return this.pageStart + this.pageOptions.pageSize;
  }

  setWord(length: number): string {
    return length < 2 ? 'file' : 'files';
  }

  setDisplayedStatus(status: OverallPackageStatus) {
    switch (status) {
      case OverallPackageStatus.PendingDestinations:
        return 'Downloading';
      case OverallPackageStatus.PendingSource:
        return 'Uploading';
      case OverallPackageStatus.AllDestinationsUpToDate:
        return 'Completed';
    }
  }

  onItemsSearched(event: { searchText: string }) {
    this.searchedFiles = event.searchText;
  }

  onSelectPackage(pck: SelectedCompletedPackageInfo) {
    this.selectedPackage = pck;
    this.selectedPackageIndex = pck.selectedPackageIndex;
    this.packageSelectedEvent.emit({
      packageId: pck.pck.packageId,
      computedConfigId: pck.pck.source?.computedConfigId,
      shareviewKey: this.shareviewKey,
    });
  }

  onPageChange(pageEvent: DpPaginatorEvent) {
    this.extendedCardContentIndex = -1;
    this.selectedPackageIndex = -1;
    this.pageOptions = pageEvent;
    this.cdr.detectChanges();
  }

  onPageChangeCompleted(pageEvent: DpPaginatorEvent): void {
    this.extendedCardContentIndex = -1;
    this.selectedPackageIndex = -1;
    this.pageOptions = pageEvent;
    this.cdr.detectChanges();
  }

  onFileSearch(searchText: string) {
    this.isSearching = searchText.length > 0;
  }

  onTabSelected(tab: number) {
    this.currentTabSelected = tab;
    this.selectedPackageIndex = this.DEFAULT_PACKAGE_NUMBER;
    this.selectedPackage = null;

    if (
      this.filteredTransferringPackages?.length > 0 &&
      this.currentTabSelected === 0
    ) {
      this.packageSelectedEvent.emit({
        packageId:
          this.selectedPackage?.packageId ??
          this.filteredTransferringPackages[0].packageId,
        computedConfigId:
          this.selectedPackage?.source.computedConfigId ??
          this.filteredTransferringPackages[0].source?.computedConfigId,
        shareviewKey: this.shareviewKey,
      });
    } else if (
      this.filteredTransferringPackages?.length == 0 &&
      this.currentTabSelected === 0
    ) {
      this.selectedPackage = null;
      this.packageSelectedEvent.emit({
        shareviewKey: '',
        computedConfigId: '',
        packageId: '',
      });
    }

    if (
      this.filteredCompletedPackages?.length > 0 &&
      this.currentTabSelected === 1
    ) {
      this.packageSelectedEvent.emit({
        packageId:
          this.selectedPackage?.packageId ??
          this.filteredCompletedPackages[0].packageId,
        computedConfigId:
          this.selectedPackage?.source.computedConfigId ??
          this.filteredCompletedPackages[0].source?.computedConfigId,
        shareviewKey: this.shareviewKey,
      });
    } else if (
      this.filteredCompletedPackages?.length == 0 &&
      this.currentTabSelected === 1
    ) {
      this.selectedPackage = null;
      this.packageSelectedEvent.emit({
        shareviewKey: '',
        computedConfigId: '',
        packageId: '',
      });
    }
  }

  getOngoingMovePackages() {
    this.shareViewMoveService
      .getOngoingMovePackagesForShareViewKey(this.shareviewKey)
      .subscribe((data: any) => {
        this.transferringPackages = data;
        this.filteredTransferringPackages = data;

        if (data.length > 0 && this.currentTabSelected === 0) {
          this.packageSelectedEvent.emit({
            packageId: this.selectedPackage?.packageId ?? data[0].packageId,
            computedConfigId:
              this.selectedPackage?.source?.computedConfigId ??
              data[0].source.computedConfigId,
            shareviewKey: this.shareviewKey,
          });
        }
      });
  }

  getCompletedMovePackages() {
    this.shareViewMoveService
      .getCompletedMovePackagesForShareViewKey(this.shareviewKey)
      .subscribe((data: any) => {
        this.completedPackages = data;
        this.filteredCompletedPackages = data;

        if (data.length > 0 && this.currentTabSelected === 1) {
          this.packageSelectedEvent.emit({
            packageId: this.selectedPackage?.packageId ?? data[0].packageId,
            computedConfigId:
              this.selectedPackage?.source?.computedConfigId ??
              data[0].source.computedConfigId,
            shareviewKey: this.shareviewKey,
          });
        }
      });
  }

  getFiles(files: ResponseFileDto[]) {
    this.searchResult = [];
    files.forEach((file) => {
      if (file.fileName?.toLocaleLowerCase().includes(this.searchedFiles)) {
        this.searchResult.push(file);
      }
    });
    return this.searchResult;
  }

  onExpandContent(extendedCardIndex: number): void {
    this.extendedCardContentIndex = extendedCardIndex;
  }
}
