<dp-header4 class="dp-mb-2">Task Destinations</dp-header4>

<dp-list-search-header
  [items]="destinations"
  placeholder="Search for destination"
  [filterProperties]="['leafNodeName']"
  (itemsFiltered)="onItemsFiltered($event)"
  variant="standard"
  class="d-block dp-mb-10"
>
</dp-list-search-header>

<dp-expandable-list
  [items]="filteredDestinations"
  [loading]="isLoading && destinations.length === 0"
  [pageable]="true"
  (expanded)="onDestinationExpanded($event)"
>
  <dp-column field="destinationStatus" width="60px">
    <dp-cell *dpCellDef="let item">
      <dp-header-cell *dpHeaderCellDef></dp-header-cell>
      <dp-icon
        *ngIf="item.destinationStatus === DestinationStatus.Downloading"
        type="la-sync-alt"
        class="icon"
        [style.background]="'var(--dualog-blue8)'"
      ></dp-icon>
      <dp-icon
        *ngIf="item.destinationStatus === DestinationStatus.Downloaded"
        type="la-check"
        class="icon"
        [style.background]="'var(--dualog-aqua)'"
        color="var(--dualog-white)"
      ></dp-icon>
      <dp-icon
        *ngIf="
          item.destinationStatus === DestinationStatus.NotStarted ||
          item.destinationStatus === DestinationStatus.Registered
        "
        type="la-satellite-dish"
        class="icon"
        [style.background]="'var(--dualog-blue8)'"
      ></dp-icon>
    </dp-cell>
  </dp-column>
  <dp-column field="leafNodeName">
    <dp-header-cell *dpHeaderCellDef>Name</dp-header-cell>
    <dp-cell *dpCellDef="let item" [title]="item.leafNodeName">
      <div class="d-flex flex-column" truncate>
        <dp-body-type [title]="item.leafNodeName" [truncate]="true">
          {{ item.leafNodeName }}
        </dp-body-type>
        <dp-progress-bar
          *ngIf="item.destinationStatus === DestinationStatus.Downloading"
          [progress]="calculateProgress(item)"
          [isFullWidth]="true"
        ></dp-progress-bar>
        <dp-detail-type>
          <ng-container
            *ngIf="item.destinationStatus === DestinationStatus.Downloaded"
          >
            Up-To-Date
          </ng-container>
          <ng-container
            *ngIf="
              item.destinationStatus === DestinationStatus.NotStarted ||
              item.destinationStatus === DestinationStatus.Registered
            "
          >
            Waiting
          </ng-container>
        </dp-detail-type>
      </div>
    </dp-cell>
  </dp-column>
  <dp-column field="currentVersion">
    <dp-header-cell *dpHeaderCellDef>Current version</dp-header-cell>
    <dp-cell *dpCellDef="let item">
      <div class="d-flex flex-column">
        <ng-container *ngIf="item.currentVersion; else noVersion">
          #{{ item.currentVersion }}
          <dp-detail-type>
            {{ item.currentVersionTime | dpDate }}
          </dp-detail-type>
        </ng-container>
        <ng-template #noVersion>
          No version
          <dp-detail-type> – </dp-detail-type>
        </ng-template>
      </div>
    </dp-cell>
  </dp-column>
  <ng-template dpListExpandTemplate let-item>
    <td width="60px"></td>
    <td>
      <dp-body-type class="dp-mt-8" small block>Progress</dp-body-type>
      <dp-body-type class="dp-mb-6" small block>Status</dp-body-type>
    </td>
    <td colspan="2">
      <dp-support-type class="dp-mt-8" block>
        {{ item.ongoingBytesConfirmed | bytes : 2 }} /
        <ng-container
          *ngIf="item.ongoingSize || !item.ongoingBytesConfirmed; else unknown"
        >
          {{ item.ongoingSize | bytes : 2 }}
        </ng-container>
        <ng-template #unknown> Unknown </ng-template>
      </dp-support-type>
      <dp-support-type class="dp-mb-6" block>
        {{ item.destinationStatus }}
      </dp-support-type>
    </td>
  </ng-template>
</dp-expandable-list>
