import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { getFormattedDuration } from '@dualog/design-system';
import { SourceStatus } from '@shareview/libs/generated/shareview-api';
import { calculateProgress } from './utils/calculate-progress';

@UntilDestroy()
@Component({
  selector: 'shareview-sync-source',
  templateUrl: './sync-source.component.html',
  styleUrls: ['./sync-source.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SyncSourceComponent {
  @Input() isLoading = false;

  SourceStatus = SourceStatus;
  sources: any[] = [];
  isSourceExpanded = false;

  @Input()
  set source(value: any) {
    if (value) {
      if (
        this.sources.length === 0 ||
        value.configId !== this.sources[0].configId
      ) {
        this.sources.splice(0, 1);
        this.sources.push(value);
      } else {
        value.isExpanded =
          this.isSourceExpanded === undefined ? false : this.isSourceExpanded;
        this.sources[0] = value;
      }
    }
  }

  getDuration(startedDateTime: Date, completedDateTime: Date): string {
    return getFormattedDuration(
      new Date(startedDateTime),
      new Date(completedDateTime),
      false
    );
  }

  calculateProgress(task: any) {
    return calculateProgress(task.bytesConfirmed, task.versionSize);
  }

  toggleSource(): void {
    this.isSourceExpanded = !this.isSourceExpanded;
  }
}
