/**
 * ShareView - public API
 * Public facing API for Dualog Drive.
 *
 * The version of the OpenAPI document: v1
 * Contact: support@dualog.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type SourceStatusDto = 'Registered' | 'Uploading' | 'Uploaded' | 'Failed';

export const SourceStatusDto = {
    Registered: 'Registered' as SourceStatusDto,
    Uploading: 'Uploading' as SourceStatusDto,
    Uploaded: 'Uploaded' as SourceStatusDto,
    Failed: 'Failed' as SourceStatusDto
};

