<dp-header2>{{ taskName }}</dp-header2>
<div class="sub-header">
  <div class="no-icon">
    <dp-header5>Move task |</dp-header5>
    <dp-support-type> Copy link</dp-support-type>
    <dp-button
      type="icon"
      icon="clipboard"
      [noPadding]="true"
      [fullWidth]="false"
      (click)="copyField()"
    ></dp-button>
  </div>
</div>
