/**
 * ShareView - public API
 * Public facing API for Dualog Drive.
 *
 * The version of the OpenAPI document: v1
 * Contact: support@dualog.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PackageTransmissionStatus = 'NotStarted' | 'Registered' | 'TransmissionInProgress' | 'TransmissionFailed' | 'TransmissionCompleted' | 'PlacedInFolder';

export const PackageTransmissionStatus = {
    NotStarted: 'NotStarted' as PackageTransmissionStatus,
    Registered: 'Registered' as PackageTransmissionStatus,
    TransmissionInProgress: 'TransmissionInProgress' as PackageTransmissionStatus,
    TransmissionFailed: 'TransmissionFailed' as PackageTransmissionStatus,
    TransmissionCompleted: 'TransmissionCompleted' as PackageTransmissionStatus,
    PlacedInFolder: 'PlacedInFolder' as PackageTransmissionStatus
};

