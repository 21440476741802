import { Component, Input } from '@angular/core';
import { getFormattedDuration } from '@dualog/design-system';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ResponseMoveSourceStatusDto, SourceStatusDto } from '@shareview/libs/generated/shareview-api';
import { calculateProgress } from '@shareview/shared/utilis';

@UntilDestroy()
@Component({
  selector: 'shareview-source',
  templateUrl: './source.component.html',
  styleUrls: ['./source.component.scss'],
})
export class SourceComponent {
  @Input() packages!: ResponseMoveSourceStatusDto[];
  isLoading!: boolean;
  SourceStatus = SourceStatusDto;
  isSourceExpanded!: boolean;
  expandedRowsIds: number[] = [];

  getDuration(startedDateTime: Date, completedDateTime: Date) {
    return getFormattedDuration(
      new Date(startedDateTime),
      new Date(completedDateTime),
      false
    );
  }

    calculateProgress(task: any) {
      return calculateProgress(task.bytesUploaded, task.size);
    }

    onSourceExpanded(card: ResponseMoveSourceStatusDto) {
      const expandedPackageIndex = this.packages.findIndex(
        (pck: ResponseMoveSourceStatusDto) => pck.sourceId === card.sourceId
      );
      if (this.expandedRowsIds.includes(expandedPackageIndex)) {
        this.expandedRowsIds.splice(expandedPackageIndex, 1);
      } else {
        this.expandedRowsIds.push(expandedPackageIndex);
      }
    }
  }

