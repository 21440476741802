import {
  ExternalClientType,
  OverallPackageStatus,
} from '@shareview/libs/generated/shareview-api';

export function setIconType(type: string): string {
  if (type === OrgUnitTypeDef.Office) {
    return 'office';
  } else if (type === OrgUnitTypeDef.OfficeGroup) {
    return 'office-group';
  } else if (type === OrgUnitTypeDef.ShipGroup) {
    return 'ship-group';
  } else if (type === OrgUnitTypeDef.Ship) {
    return 'ship';
  } else if (type === ExternalClientType.SharePoint) {
    return 'la-microsoft';
  } else {
    console.error(`Got an unexpected installation type ${type}`);
    return '';
  }
}

export function setDisplayedStatus(status: OverallPackageStatus): string {
  switch (status) {
    case OverallPackageStatus.PendingDestinations:
      return 'Downloading';
    case OverallPackageStatus.PendingSource:
      return 'Uploading';
    case OverallPackageStatus.AllDestinationsUpToDate:
      return 'Completed';
    default:
      return 'Unknown';
  }
}

export function setFileTitle(length: number): string {
  if (length < 2) {
    return 'file';
  } else {
    return 'files';
  }
}

export enum OrgUnitTypeDef {
  Shipping = 'Shipping',
  Office = 'Office',
  OfficeGroup = 'OfficeGroup',
  Ship = 'Ship',
  ShipGroup = 'ShipGroup',
  SharePoint = 'SharePoint',
  S3 = 'S3',
  Mixed = 'Mixed',
}
