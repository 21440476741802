import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DualogDesignSystemModule } from '@dualog/design-system';
import { SharedUtilisModule } from '@shareview/shared/utilis';
import { NgMathPipesModule, BytesPipe } from 'ngx-pipes';
import { GeneralInformationComponent } from './general-information/general-information.component';
import { LandingpageComponent } from './landingpage/landingpage.component';
import { SyncRoutingModule } from './sync.routes';
import { SyncFolderPreviewComponent } from './sync-folder-preview/sync-folder-preview.component';
import { SyncTaskVersionsHistoryModal } from './sync-folder-preview/sync-task-versions-history-modal/sync-task-versions-history.modal';
import { CdkTreeModule } from '@angular/cdk/tree';
import { FormsModule } from '@angular/forms';
import { SyncSourceComponent } from './sync-source/sync-source.component';
import { SyncDestinationsComponent } from './sync-destinations/sync-destinations.component';
import { SyncService } from './shared/sync.service';
import { GeneralInformationService } from './shared/general-information.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DualogDesignSystemModule,
    SharedUtilisModule,
    NgMathPipesModule,
    SyncRoutingModule,
    CdkTreeModule,
  ],
  declarations: [
    LandingpageComponent,
    GeneralInformationComponent,
    SyncFolderPreviewComponent,
    SyncTaskVersionsHistoryModal,
    SyncSourceComponent,
    SyncDestinationsComponent,
  ],
  exports: [GeneralInformationComponent],
  providers: [BytesPipe, SyncService, GeneralInformationService],
})
export class ShareviewFeatureSyncModule {}
