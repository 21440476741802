<div class="not-found">
  <div class="logo-container">
    <p class="product-name">Dualog Shareview</p>
    <dp-icon [size]="80" type="digitalplatform"> </dp-icon>
  </div>
  <div class="text">
    <p class="dp-mt-30">
      Dualog ShareView could not find any information for this link.
    </p>
    <p class="dp-mt-12">It could be expired or deleted.</p>
    <p class="dp-mt-12">
      For further information contact Dualog Support or your IT Admin.
    </p>
  </div>
</div>
