/**
 * ShareView - public API
 * Public facing API for Dualog Drive.
 *
 * The version of the OpenAPI document: v1
 * Contact: support@dualog.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ExternalClientType = 'Installation' | 'SharePoint' | 'AmazonS3';

export const ExternalClientType = {
    Installation: 'Installation' as ExternalClientType,
    SharePoint: 'SharePoint' as ExternalClientType,
    AmazonS3: 'AmazonS3' as ExternalClientType
};

