/**
 * ShareView - public API
 * Public facing API for Dualog Drive.
 *
 * The version of the OpenAPI document: v1
 * Contact: support@dualog.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { GeneralInformationWithSourcesDto } from '../model/generalInformationWithSourcesDto';
// @ts-ignore
import { ProblemDetails } from '../model/problemDetails';
// @ts-ignore
import { ResponseSyncDestinationStatusDto } from '../model/responseSyncDestinationStatusDto';
// @ts-ignore
import { ResponseSyncFilesHistoryHeaderDto } from '../model/responseSyncFilesHistoryHeaderDto';
// @ts-ignore
import { ResponseSyncVersionSourceStatusDto } from '../model/responseSyncVersionSourceStatusDto';
// @ts-ignore
import { V1SyncVersionContentDto } from '../model/v1SyncVersionContentDto';
// @ts-ignore
import { V1SyncVersionDto } from '../model/v1SyncVersionDto';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class SyncService {

    protected basePath = 'http://dr-shareview-public-api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString());
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param shareViewKey 
     * @param computedConfigId 
     * @param startRow 
     * @param fileNameSearch 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFileHistoryInTask(shareViewKey: string, computedConfigId: string, startRow: number, fileNameSearch?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<ResponseSyncFilesHistoryHeaderDto>;
    public getFileHistoryInTask(shareViewKey: string, computedConfigId: string, startRow: number, fileNameSearch?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<ResponseSyncFilesHistoryHeaderDto>>;
    public getFileHistoryInTask(shareViewKey: string, computedConfigId: string, startRow: number, fileNameSearch?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<ResponseSyncFilesHistoryHeaderDto>>;
    public getFileHistoryInTask(shareViewKey: string, computedConfigId: string, startRow: number, fileNameSearch?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (shareViewKey === null || shareViewKey === undefined) {
            throw new Error('Required parameter shareViewKey was null or undefined when calling getFileHistoryInTask.');
        }
        if (computedConfigId === null || computedConfigId === undefined) {
            throw new Error('Required parameter computedConfigId was null or undefined when calling getFileHistoryInTask.');
        }
        if (startRow === null || startRow === undefined) {
            throw new Error('Required parameter startRow was null or undefined when calling getFileHistoryInTask.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (computedConfigId !== undefined && computedConfigId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>computedConfigId, 'ComputedConfigId');
        }
        if (fileNameSearch !== undefined && fileNameSearch !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>fileNameSearch, 'FileNameSearch');
        }
        if (startRow !== undefined && startRow !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>startRow, 'StartRow');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/Sync/ShareViewKey/${this.configuration.encodeParam({name: "shareViewKey", value: shareViewKey, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/SyncFileHistory`;
        return this.httpClient.request<ResponseSyncFilesHistoryHeaderDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param shareViewKey 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSyncGeneralInformation(shareViewKey: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<GeneralInformationWithSourcesDto>;
    public getSyncGeneralInformation(shareViewKey: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<GeneralInformationWithSourcesDto>>;
    public getSyncGeneralInformation(shareViewKey: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<GeneralInformationWithSourcesDto>>;
    public getSyncGeneralInformation(shareViewKey: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (shareViewKey === null || shareViewKey === undefined) {
            throw new Error('Required parameter shareViewKey was null or undefined when calling getSyncGeneralInformation.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/Sync/ShareViewKey/${this.configuration.encodeParam({name: "shareViewKey", value: shareViewKey, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/GeneralInformation`;
        return this.httpClient.request<GeneralInformationWithSourcesDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param shareViewKey 
     * @param configComputedId 
     * @param versionNo 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSyncTaskContentForGivenVersion(shareViewKey: string, configComputedId: string, versionNo: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<V1SyncVersionContentDto>;
    public getSyncTaskContentForGivenVersion(shareViewKey: string, configComputedId: string, versionNo: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<V1SyncVersionContentDto>>;
    public getSyncTaskContentForGivenVersion(shareViewKey: string, configComputedId: string, versionNo: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<V1SyncVersionContentDto>>;
    public getSyncTaskContentForGivenVersion(shareViewKey: string, configComputedId: string, versionNo: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (shareViewKey === null || shareViewKey === undefined) {
            throw new Error('Required parameter shareViewKey was null or undefined when calling getSyncTaskContentForGivenVersion.');
        }
        if (configComputedId === null || configComputedId === undefined) {
            throw new Error('Required parameter configComputedId was null or undefined when calling getSyncTaskContentForGivenVersion.');
        }
        if (versionNo === null || versionNo === undefined) {
            throw new Error('Required parameter versionNo was null or undefined when calling getSyncTaskContentForGivenVersion.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (versionNo !== undefined && versionNo !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>versionNo, 'versionNo');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/Sync/ShareViewKey/${this.configuration.encodeParam({name: "shareViewKey", value: shareViewKey, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/configcomputed/${this.configuration.encodeParam({name: "configComputedId", value: configComputedId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/Content`;
        return this.httpClient.request<V1SyncVersionContentDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param shareViewKey 
     * @param configComputedId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSyncTaskDestinationStatus(shareViewKey: string, configComputedId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<ResponseSyncDestinationStatusDto>;
    public getSyncTaskDestinationStatus(shareViewKey: string, configComputedId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<ResponseSyncDestinationStatusDto>>;
    public getSyncTaskDestinationStatus(shareViewKey: string, configComputedId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<ResponseSyncDestinationStatusDto>>;
    public getSyncTaskDestinationStatus(shareViewKey: string, configComputedId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (shareViewKey === null || shareViewKey === undefined) {
            throw new Error('Required parameter shareViewKey was null or undefined when calling getSyncTaskDestinationStatus.');
        }
        if (configComputedId === null || configComputedId === undefined) {
            throw new Error('Required parameter configComputedId was null or undefined when calling getSyncTaskDestinationStatus.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/Sync/ShareViewKey/${this.configuration.encodeParam({name: "shareViewKey", value: shareViewKey, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/configcomputed/${this.configuration.encodeParam({name: "configComputedId", value: configComputedId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/DestinationStatus`;
        return this.httpClient.request<ResponseSyncDestinationStatusDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param shareViewKey 
     * @param configComputedId 
     * @param versionNo 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSyncTaskSourceStatus(shareViewKey: string, configComputedId: string, versionNo: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<ResponseSyncVersionSourceStatusDto>;
    public getSyncTaskSourceStatus(shareViewKey: string, configComputedId: string, versionNo: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<ResponseSyncVersionSourceStatusDto>>;
    public getSyncTaskSourceStatus(shareViewKey: string, configComputedId: string, versionNo: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<ResponseSyncVersionSourceStatusDto>>;
    public getSyncTaskSourceStatus(shareViewKey: string, configComputedId: string, versionNo: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (shareViewKey === null || shareViewKey === undefined) {
            throw new Error('Required parameter shareViewKey was null or undefined when calling getSyncTaskSourceStatus.');
        }
        if (configComputedId === null || configComputedId === undefined) {
            throw new Error('Required parameter configComputedId was null or undefined when calling getSyncTaskSourceStatus.');
        }
        if (versionNo === null || versionNo === undefined) {
            throw new Error('Required parameter versionNo was null or undefined when calling getSyncTaskSourceStatus.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (versionNo !== undefined && versionNo !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>versionNo, 'versionNo');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/Sync/ShareViewKey/${this.configuration.encodeParam({name: "shareViewKey", value: shareViewKey, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/configcomputed/${this.configuration.encodeParam({name: "configComputedId", value: configComputedId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/SourceStatus`;
        return this.httpClient.request<ResponseSyncVersionSourceStatusDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param shareViewKey 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public incrementSyncCounter(shareViewKey: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<boolean>;
    public incrementSyncCounter(shareViewKey: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<boolean>>;
    public incrementSyncCounter(shareViewKey: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<boolean>>;
    public incrementSyncCounter(shareViewKey: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (shareViewKey === null || shareViewKey === undefined) {
            throw new Error('Required parameter shareViewKey was null or undefined when calling incrementSyncCounter.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/Sync/ShareViewKey/${this.configuration.encodeParam({name: "shareViewKey", value: shareViewKey, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/IncrementCounter`;
        return this.httpClient.request<boolean>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param shareViewKey 
     * @param configComputedId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public versions(shareViewKey: string, configComputedId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<Array<V1SyncVersionDto>>;
    public versions(shareViewKey: string, configComputedId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<Array<V1SyncVersionDto>>>;
    public versions(shareViewKey: string, configComputedId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<Array<V1SyncVersionDto>>>;
    public versions(shareViewKey: string, configComputedId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (shareViewKey === null || shareViewKey === undefined) {
            throw new Error('Required parameter shareViewKey was null or undefined when calling versions.');
        }
        if (configComputedId === null || configComputedId === undefined) {
            throw new Error('Required parameter configComputedId was null or undefined when calling versions.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/Sync/ShareViewKey/${this.configuration.encodeParam({name: "shareViewKey", value: shareViewKey, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/configcomputed/${this.configuration.encodeParam({name: "configComputedId", value: configComputedId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/Versions`;
        return this.httpClient.request<Array<V1SyncVersionDto>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
