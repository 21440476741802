<dp-page-container>
  <div class="row">
    <div class="col-12 col-xl-7 col-lg-6 mb-lg-4">
      <dp-header4 class="dp-mb-6">Folder Preview</dp-header4>
      <shareview-sync-folder-preview
        [versions]="versions"
        [latestVersion]="latestVersion"
        [selectedVersion]="selectedVersion"
        [syncVersionContent]="syncVersionContent"
        [isLoading]="isLoadingSyncContent"
        (versionChanged)="onVersionChange($event)"
      >
      </shareview-sync-folder-preview>

      <hr class="dp-my-8 d-block d-lg-none" />
    </div>

    <div class="col-12 col-xl-4 col-lg-6 offset-xl-1 offset-lg-0">
      <shareview-sync-source
        [source]="source"
        [isLoading]="isLoadingSource"
      ></shareview-sync-source>
      <shareview-sync-destinations
        [destination]="destination"
        [isLoading]="isLoadingDestinations"
      ></shareview-sync-destinations>
    </div>
  </div>
</dp-page-container>
