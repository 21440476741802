import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { getFormattedDuration } from '@dualog/design-system';
import {
  ResponseMoveDestinationStatusDto,
  DestinationStatusDto,
} from '@shareview/libs/generated/shareview-api';
import { calculateProgress } from '@shareview/shared/utilis';
import { sortMoveDestinationsByDestinationStatus } from '../general-information/utils/destinations-utils';

@UntilDestroy()
@Component({
  selector: 'shareview-destinations',
  templateUrl: './destinations.component.html',
  styleUrls: ['./destinations.component.scss'],
})
export class DestinationsComponent implements OnChanges {
  @Input() packages!: ResponseMoveDestinationStatusDto[];
  isLoading!: boolean;
  DestinationStatus = DestinationStatusDto;
  expandedRowsIds: number[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    this.packages = this.packages.sort(sortMoveDestinationsByDestinationStatus);
  }

  onDestinationExpanded(card: ResponseMoveDestinationStatusDto) {
    const expandedPackageIndex = this.packages.findIndex(
      (pck: ResponseMoveDestinationStatusDto) =>
        pck.destinationId === card.destinationId
    );
    if (this.expandedRowsIds.includes(expandedPackageIndex)) {
      this.expandedRowsIds.splice(expandedPackageIndex, 1);
    } else {
      this.expandedRowsIds.push(expandedPackageIndex);
    }
  }

  getDuration(startedDateTime: Date, completedDateTime: Date): string {
    return getFormattedDuration(
      new Date(startedDateTime),
      new Date(completedDateTime),
      false
    );
  }

  calculateProgress(task: any): number {
    return calculateProgress(task.bytesDownloaded, task.size);
  }
}
