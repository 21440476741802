<dp-modal-large
  [allowCommonExitStrategies]="false"
  header="File Version History"
  [openWindow]="isModalOpen"
  (cancel)="closeModal()"
>
  <dp-modal-body>
    <dp-input
      [disabled]="isLoading"
      [fullWidth]="true"
      icon="las la-search"
      label="Search for file"
      [(ngModel)]="fileNameSearch"
      (valueSubmitted)="filterFileVersionHistory()"
    >
    </dp-input>
    <hr />
    <div *ngIf="!isLoading; else showSkeleton" class="versions-list">
      <div
        *ngFor="let versionGroup of syncFileVersionGroups | keyvalue; let i = index"
      >
        <ng-container *ngIf="i > 0">
          <hr class="divider" />
        </ng-container>
        <dp-header5>{{versionGroup.value[0].serverTime}}</dp-header5>
        <dp-detail-type>
          Updated files {{versionGroup.value.length}} - Total size: {{
          getGroupOfFilesSize(versionGroup.value) | bytes : 2 }}
        </dp-detail-type>
        <div *ngFor="let version of versionGroup.value">
          <dp-support-type block>{{ version.action }}</dp-support-type>{{ ' ' }}
          <dp-body-type block>{{ version.itemName }}</dp-body-type>
          <dp-support-type *ngIf="getFileVersionHistoryString(version)">
            {{ getFileVersionHistoryString(version) }}
          </dp-support-type>
        </div>
      </div>
    </div>
    <hr />
    <div class="text-center w-100">
      <dp-button
        type="tertiary"
        [disabled]="isLoading || !hasMoreRows"
        (clicked)="showOlderHistory()"
      >
        Show Older History
      </dp-button>
    </div>
    <ng-template #showSkeleton>
      <div class="loading-skeleton">
        <ng-container *ngFor="let counter of [1,2,3,4,5,6]">
          <dp-skeleton width="400"></dp-skeleton>
          <br />
          <dp-skeleton type="small" width="600"></dp-skeleton>
          <br />
          <dp-skeleton type="small" width="600"></dp-skeleton>
          <br />
        </ng-container>
      </div>
    </ng-template>
  </dp-modal-body>
</dp-modal-large>
