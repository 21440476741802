/**
 * ShareView - public API
 * Public facing API for Dualog Drive.
 *
 * The version of the OpenAPI document: v1
 * Contact: support@dualog.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type SourceStatus = 'Registered' | 'Uploading' | 'Uploaded';

export const SourceStatus = {
    Registered: 'Registered' as SourceStatus,
    Uploading: 'Uploading' as SourceStatus,
    Uploaded: 'Uploaded' as SourceStatus
};

