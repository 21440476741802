/**
 * ShareView - public API
 * Public facing API for Dualog Drive.
 *
 * The version of the OpenAPI document: v1
 * Contact: support@dualog.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SyncSourceDto } from './syncSourceDto';
import { TemplateType } from './templateType';


export interface GeneralInformationWithSourcesDto { 
    taskName?: string | null;
    url?: string | null;
    folderType?: string | null;
    sources?: Array<SyncSourceDto> | null;
    templateType?: TemplateType;
}
export namespace GeneralInformationWithSourcesDto {
}


