/**
 * ShareView - public API
 * Public facing API for Dualog Drive.
 *
 * The version of the OpenAPI document: v1
 * Contact: support@dualog.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { V1SyncFileDto } from './v1SyncFileDto';
import { V1SyncFolderDto } from './v1SyncFolderDto';


export interface V1SyncVersionContentDto { 
    configComputedId?: string;
    versionNo?: number;
    folders?: Array<V1SyncFolderDto> | null;
    files?: Array<V1SyncFileDto> | null;
}

