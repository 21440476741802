/**
 * ShareView - public API
 * Public facing API for Dualog Drive.
 *
 * The version of the OpenAPI document: v1
 * Contact: support@dualog.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TypedSourceMoveStatusDto } from './typedSourceMoveStatusDto';
import { OverallPackageStatus } from './overallPackageStatus';
import { ResponseFileDto } from './responseFileDto';
import { TypedDestinationStatusDto } from './typedDestinationStatusDto';


export interface ResponsePackageWithContentDto { 
    configId?: string;
    packageId?: string;
    serverTime?: Date;
    size?: number;
    packageStatus?: OverallPackageStatus;
    source?: TypedSourceMoveStatusDto;
    destinations?: Array<TypedDestinationStatusDto> | null;
    files?: Array<ResponseFileDto> | null;
}
export namespace ResponsePackageWithContentDto {
}


