export function copyField(field: string) {
  const textarea = createTextAreaWithText(field);

  const successful = document.execCommand('copy');

  if (textarea && textarea.parentNode) {
    textarea.parentNode.removeChild(textarea);
  }
  return successful;
}

function createTextAreaWithText(text: string) {
  let textarea = null;
  textarea = document.createElement('textarea');
  textarea.style.height = '0px';
  textarea.style.opacity = '0';
  textarea.style.position = 'fixed';
  textarea.style.width = '0px';
  document.body.appendChild(textarea);
  textarea.value = text;
  textarea.select();

  return textarea;
}
