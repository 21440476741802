<div *ngIf="!loading">
  <dp-tabs
    [selectedTab]="_filteredTransferringPackages?.length! > 0 ? 0 : 1"
    [disableTooltip]="false"
    (tabSelected)="onTabSelected($event)"
  >
    <dp-tab
      tabTitle="Transferring ({{ filteredTransferringPackages?.length }})"
      tooltipText="Transferring packages last year (max 1000)"
    >
      <dp-info-card
        *ngIf="filteredTransferringPackages?.length === 0"
        icon="pending"
        header="No transfers"
        subheader="There are no current transfers yet. Please try again if you were expecting active transfers."
      >
      </dp-info-card>

      <div *ngFor="let package of _filteredTransferringPackages; let i = index">
        <shareview-package-card
          [package]="package"
          [isTransferringTabSelected]="true"
          [index]="i"
          [organisationInfo]="''"
          [isSelected]="selectedPackageIndex === i"
          [isExpanded]="extendedCardContentIndex === i"
          [extendedCardContentIndex]="extendedCardContentIndex"
          (selectedTransferringPackage)="onSelectPackage($event)"
          (changedExtendedCardContentIndex)="onExpandContent($event)"
        >
        </shareview-package-card>
      </div>
      <dp-paginator
        class="dp-mt-2"
        (changed)="onPageChange($event)"
        [length]="filteredTransferringPackages?.length"
        [pageSize]="pageOptions.pageSize"
      ></dp-paginator>
    </dp-tab>

    <dp-tab
      tabTitle="Completed"
      tooltipText="Completed packages last year (max 1000)"
    >
      <dp-info-card
        *ngIf="filteredCompletedPackages?.length === 0"
        icon="pending"
        header="No transfers"
        subheader="There are no completed transfers yet. Try looking in the Transferring list to see if the transfers are still pending."
      >
      </dp-info-card>

      <div *ngFor="let package of _filteredCompletedPackages; let i = index">
        <shareview-package-card
          [package]="package"
          [isTransferringTabSelected]="false"
          [index]="i"
          [organisationInfo]="''"
          [isSelected]="selectedPackageIndex === i"
          [isExpanded]="extendedCardContentIndex === i"
          [extendedCardContentIndex]="extendedCardContentIndex"
          (selectedCompletedPackage)="onSelectPackage($event)"
          (changedExtendedCardContentIndex)="onExpandContent($event)"
        >
        </shareview-package-card>
      </div>
      <dp-paginator
        class="dp-mt-2"
        (changed)="onPageChangeCompleted($event)"
        [length]="filteredCompletedPackages?.length"
        [pageSize]="pageOptions.pageSize"
      ></dp-paginator>
    </dp-tab>
  </dp-tabs>

  <hr class="dp-my-8 d-block d-lg-none" />
</div>
