import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LandingpageComponent } from './landingpage/landingpage.component';
import { GeneralInformationComponent } from './general-information/general-information.component';
import { DualogDesignSystemModule } from '@dualog/design-system';
import { SharedUtilisModule } from '@shareview/shared/utilis';
import { TransmissionListComponent } from './transmission-list/transmission-list.component';
import { NgMathPipesModule } from 'ngx-pipes';
import { MoveRoutingModule } from './move.routes';
import { SourceComponent } from './source/source.component';
import { DestinationsComponent } from './destinations/destinations.component';
import { PackageCardComponent } from './transmission-list/package-card/package-card.component';

@NgModule({
  imports: [
    CommonModule,
    DualogDesignSystemModule,
    SharedUtilisModule,
    NgMathPipesModule,
    MoveRoutingModule,
  ],
  declarations: [
    LandingpageComponent,
    GeneralInformationComponent,
    TransmissionListComponent,
    DestinationsComponent,
    SourceComponent,
    PackageCardComponent,
  ],
  exports: [GeneralInformationComponent],
})
export class ShareviewFeatureMoveModule {}
