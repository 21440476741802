<dp-expandable-card
  class="dp-mb-4 expCard"
  header="{{ package.files.length }} {{ setFileTitle(package.files.length) }} -
{{ package.size | bytes : 0 }} - {{
    setDisplayedStatus(package.packageStatus)
  }}"
  [isSelected]="isSelected"
  [showContent]="extendedCardContentIndex === index"
  (clicked)="
    isTransferringTabSelected
      ? onSelectTransferringPackage(index, package)
      : onSelectCompletePackage(index, package)
  "
  (expandedClicked)="onExpandContent($event, index)"
>
  <div displayedDetailsLeftNotExpanded>
    <div class="fileNames" *ngFor="let number of [0, 1]">
      <dp-detail-type>{{ package.files[number]?.fileName }}</dp-detail-type>
    </div>
    <dp-detail-type *ngIf="package.files.length > 2">
      + {{ package.files.length - 2 }} other
      {{ setFileTitle(package.files.length - 2) }}</dp-detail-type
    >
  </div>
  <div displayedDetailsLeftExpanded>
    <dp-list-search-header
      [items]="package.files"
      isClearVisible="true"
      placeholder="Search for files"
      (changed)="onFileSearch($event)"
      (itemsFiltered)="onItemsSearched($event)"
    ></dp-list-search-header>
  </div>
  <div displayedDetailsRight>
    <div class="align-vertically">
      <dp-icon type="clock" class="dp-mr-2" [size]="16"></dp-icon>
      <dp-detail-type class="display-inline-block no-margin-bottom">{{
        package.serverTime | dpDateTime
      }}</dp-detail-type>
    </div>
    <div class="align-vertically">
      <dp-icon
        type="{{ setIconType(package.source.orgUnitType) }}"
        class="dp-mr-2"
        [size]="16"
      ></dp-icon>
      <dp-detail-type class="display-inline-block no-margin-bottom"
        >From {{ package.source.sourceLeafNodeName }}</dp-detail-type
      >
    </div>
    <div class="align-vertically">
      <dp-icon
        *ngIf="package.destinations.length === 1"
        type="{{ setIconType(package.destinations[0].orgUnitType) }}"
        class="dp-mr-2"
        [size]="16"
      ></dp-icon>
      <dp-icon
        *ngIf="package.destinations.length > 1"
        type="ship-group"
        class="dp-mr-2"
        [size]="16"
      ></dp-icon>
      <dp-detail-type
        *ngIf="package.destinations.length > 1"
        class="display-inline-block no-margin-bottom"
        >To Multiple Destinations
      </dp-detail-type>
      <dp-detail-type
        *ngIf="package.destinations.length === 1"
        class="display-inline-block no-margin-bottom"
        >To {{ package.destinations[0].leafNodeName }}
      </dp-detail-type>
    </div>
  </div>
  <div expdandedContent>
    <dp-advanced-list
      class="dp-mt-4"
      [items]="isSearching ? filterSearchedFiles(package.files) : package.files"
      [pageable]="true"
      [pageSize]="pageOptions.pageSize"
      [pageIndex]="selectedPackagePaginatorIndex"
      [selectable]="false"
      [clickable]="false"
      [defaultSortedBy]="'fileName'"
      [defaultSortDirection]="'ASCENDING'"
      (pageIndexChanged)="onFilesIndexChanged($event)"
    >
      <dp-column field="fileName" width="40%">
        <dp-header-cell *dpHeaderCellDef>File Name</dp-header-cell>
        <dp-cell *dpCellDef="let item">{{ item.fileName }}</dp-cell>
      </dp-column>
      <dp-column field="size" width="10%">
        <dp-header-cell *dpHeaderCellDef>Size</dp-header-cell>
        <dp-cell *dpCellDef="let item">{{ item.size | bytes : 0 }}</dp-cell>
      </dp-column>
      <dp-column field="modTime" width="20%">
        <dp-header-cell *dpHeaderCellDef>Modified</dp-header-cell>
        <dp-cell *dpCellDef="let item">{{ item.modTime | dpDateTime }}</dp-cell>
      </dp-column>
      <dp-column field="path" width="30%">
        <dp-header-cell *dpHeaderCellDef>Source folder</dp-header-cell>
        <dp-cell *dpCellDef="let item">{{ item.path }}</dp-cell>
      </dp-column>
    </dp-advanced-list>
  </div>
</dp-expandable-card>
